import { AccommodationSpecificity, AccommodationStatus, PatientGender, Practitioner } from '@ambuliz/sabri-core';
import { Box, Chip, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Comment } from 'common/components/Icons';
import TimelineRowEmoji, { TimelineRowEmojiProps } from 'common/components/Timeline/TimelineRowEmoji';
import TimelineRowItem, { TimelineRowItemProps } from 'common/components/Timeline/TimelineRowItem';
import { i18n } from 'common/locale';
import { color as themeColor } from 'common/theme';
import { formatPractitionerName } from 'common/utils';
import getShortDuration from 'common/utils/getShortDuration';
import { CardColors, getInfoTextColor } from 'kurt/components/EventCard/cardColors';
import { useRef } from 'react';

const PRACTITIONERS_CHIP_MAX_WIDTH = 300;

type AccommodationCardEventProps = {
  specificities?: AccommodationSpecificity[];
  practitioners?: Practitioner[];
  patient: { name: string; gender: PatientGender; birthdate: Date };
  status: AccommodationStatus;
  isEstimatedEnd: boolean;
  comment?: string;
  emoji: TimelineRowEmojiProps;
} & TimelineRowItemProps;

const AccommodationCardEvent = ({
  id,
  start,
  end,
  specificities = [],
  practitioners = [],
  comment,
  patient,
  status,
  isEstimatedEnd,
  onClick,
  emoji,
  color,
  striped,
  size = 'medium',
  ...props
}: AccommodationCardEventProps) => {
  const duration = end ? getShortDuration(start, end) : null;
  const cardContainer = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const rowItemStackProps: StackProps =
    size === 'medium'
      ? {
          spacing: 3,
          direction: 'row',
          alignItems: 'center',
        }
      : {
          spacing: '1px',
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: `center`,
          width: `100%`,
          paddingY: '3px',
        };

  return (
    <TimelineRowItem
      onClick={onClick ? () => onClick(id) : undefined}
      start={start}
      end={end}
      color={color}
      borderLeft={['IN_PROGRESS', 'COMPLETED'].includes(status)}
      borderRight={!!end && !isEstimatedEnd}
      striped={striped}
      size={size}
      ref={cardContainer}
      cardContainerRef={cardContainer}
      contentRef={contentRef}
      {...props}
    >
      <Stack
        ref={contentRef}
        spacing={3}
        direction="row"
        alignItems="center"
        flexWrap="nowrap"
        flexShrink={0}
        paddingX={4}
        position="relative"
        flex={size === 'large' ? 1 : undefined}
      >
        <TimelineRowEmoji {...emoji} />
        <Stack {...rowItemStackProps}>
          {size === 'medium' ? (
            <Typography fontWeight={600} noWrap>
              {patient.name}
            </Typography>
          ) : (
            <EllipsisTypography fontWeight={600} noWrap>
              {patient.name}
            </EllipsisTypography>
          )}
          {(comment || specificities.length > 0 || duration || (size === 'medium' && practitioners.length > 0)) && (
            <Stack
              divider={<Box bgcolor={getInfoTextColor(color)} width={4} height={4} borderRadius={2} />}
              columnGap={2}
              alignItems="center"
              direction="row"
              flexWrap={size === 'medium' ? 'nowrap' : 'wrap'}
            >
              {comment && (
                <Tooltip
                  title={
                    <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
                      {comment}
                    </Typography>
                  }
                  placement="top"
                >
                  <div>
                    <Comment sx={{ fontSize: 12, color: getInfoTextColor(color) }} />
                  </div>
                </Tooltip>
              )}
              {duration && (
                <Typography variant="body2" color={getInfoTextColor(color)} noWrap>
                  {duration}
                </Typography>
              )}
              {size === 'medium' && practitioners.length > 0 && (
                <PractionnersList practitioners={practitioners} color={color} />
              )}
              {specificities.map((specificity) => (
                <Typography key={specificity} variant="body2" color={getInfoTextColor(color)} noWrap>
                  {i18n.accommodationSpecificities[specificity]}
                </Typography>
              ))}
            </Stack>
          )}
          {size === 'large' && <PractionnersList practitioners={practitioners} color={color} />}
        </Stack>
      </Stack>
    </TimelineRowItem>
  );
};

type PractionnersListProps = {
  practitioners: Practitioner[];
  color?: CardColors;
};
const PractionnersList = ({ practitioners, color }: PractionnersListProps) => {
  if (practitioners.length === 0) {
    return null;
  }
  const remainingPractitionerNames = practitioners.slice(1);

  return (
    <Stack direction="row" gap={1} alignItems="center" maxWidth={PRACTITIONERS_CHIP_MAX_WIDTH}>
      <Chip
        key={practitioners[0].id}
        label={
          <EllipsisTypography fontSize="inherit" fontWeight="inherit" lineHeight="inherit">
            {formatPractitionerName(practitioners[0].firstName, practitioners[0].lastName)}
          </EllipsisTypography>
        }
        color="secondary"
        variant="light"
        sx={{ color: customChipColor[color || 'primary'] }}
      />
      {remainingPractitionerNames.length > 0 && (
        <Typography variant="body2" color={getInfoTextColor(color)}>
          +{remainingPractitionerNames.length}
        </Typography>
      )}
    </Stack>
  );
};

const customChipColor: Record<CardColors, Record<'color' | 'backgroundColor', string>> = {
  danger: {
    color: themeColor.orange[100],
    backgroundColor: themeColor.orange[10],
  },
  error: {
    color: themeColor.red[90],
    backgroundColor: themeColor.red[10],
  },
  primary: {
    color: themeColor.blue[40],
    backgroundColor: themeColor.blue[5],
  },
  primaryAlt: {
    color: themeColor.blue[30],
    backgroundColor: themeColor.blue[5],
  },
  secondary: {
    color: themeColor.grey[90],
    backgroundColor: themeColor.grey[10],
  },
  success: {
    color: themeColor.green[90],
    backgroundColor: themeColor.green[10],
  },
  warning: {
    color: themeColor.yellow[90],
    backgroundColor: themeColor.yellow[10],
  },
};

export default AccommodationCardEvent;
