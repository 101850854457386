import { Practitioner } from '@ambuliz/sabri-core';
import { IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import PencilIcon from 'common/components/Icons/Pencil';
import TrashCanIcon from 'common/components/Icons/TrashCan';
import { PractitionersChip } from 'common/components/Practitioners';
import { i18n } from 'common/locale';
import { useNavigate } from 'react-router-dom';

type PractitionersListItemProps = {
  practitioner: Practitioner;
};

const PractitionersListItem = ({ practitioner }: PractitionersListItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`./${practitioner.id}/update`);
  };

  const handleRemoveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`./${practitioner.id}/remove`);
  };

  return (
    <TableRow onClick={handleClick} sx={{ cursor: 'pointer' }}>
      <TableCell>
        <Typography fontWeight={600}>
          {practitioner.lastName.toUpperCase()} {practitioner.firstName}
        </Typography>
      </TableCell>
      <TableCell>
        <PractitionersChip practitioners={[practitioner]} />
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end">
          <Tooltip title={i18n.updatePractitionerDialog.title} arrow={false} placement="top">
            <IconButton onClick={handleClick} variant="filled" size="small">
              <PencilIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={i18n.removePractitionerDialog.title} arrow={false} placement="top">
            <IconButton onClick={handleRemoveClick} variant="filled" size="small">
              <TrashCanIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default PractitionersListItem;
