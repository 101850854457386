import Api, { Accommodation } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useMemo } from 'react';

const useAdmissionAccommodation = (accommodationId: string) => {
  const { results: accommodations, isLoading: accommodationsLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .include('visit', 'bed', 'unit', 'basedOn', 'basedOn.performerRequests', 'practitioners')
      .equalTo('objectId', accommodationId!)
      .limit(1)
  );
  const accommodation = useMemo(() => (!!accommodations?.length ? accommodations[0] : undefined), [accommodations]);

  const { results: previousAccommodations, isLoading: previousAccommodationsLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .notEqualTo('status', 'CANCELLED')
      .equalTo('visit', accommodation?.basedOn?.visit)
      .lessThan('startAt', accommodation?.startAt)
      .descending('startAt')
      .include('visit', 'unit.name', 'bed.name', 'practitioners')
      .limit(1),
    { enabled: !!accommodation?.basedOn }
  );
  const previousAccommodation = useMemo(
    () => (!!previousAccommodations?.length ? previousAccommodations[0] : undefined),
    [previousAccommodations]
  );

  return {
    accommodation,
    previousAccommodation,
    mutation: accommodation?.basedOn,
    loading: accommodationsLoading || previousAccommodationsLoading,
    status: (accommodation && ['IN_PROGRESS', 'COMPLETED'].includes(accommodation?.status)
      ? 'completed'
      : accommodation?.status === 'PLANNED' && !!accommodation?.bed
        ? 'validated'
        : 'planned') as 'completed' | 'planned' | 'validated' | 'requested',
  };
};

export default useAdmissionAccommodation;
