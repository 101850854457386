import { AdminNavigationRoute } from '@ambuliz/sabri-core';
import { i18n } from 'common/locale';

const root = '/admin';

type AdminRoute = {
  route: AdminNavigationRoute;
  path: string;
  label: string;
};

type AdminRouteName =
  | 'root'
  | 'healthCenter'
  | 'units'
  | 'areas'
  | 'users'
  | 'patients'
  | 'appointments'
  | 'externalTransports'
  | 'healthcareEnvironmentalCleaning'
  | 'anomalies'
  | 'emergencies'
  | 'thesaurus'
  | 'overview'
  | 'practitioners';

const routes: Record<AdminRouteName, AdminRoute> = {
  root: {
    route: 'admin',
    path: root,
    label: i18n.admin,
  },
  healthCenter: {
    route: 'admin.healthCenter',
    path: root + '/healthcenter',
    label: i18n.healthCenter,
  },
  units: {
    route: 'admin.units',
    path: root + '/units',
    label: i18n.units,
  },
  areas: {
    route: 'admin.areas',
    path: root + '/areas',
    label: i18n.areas,
  },
  users: {
    route: 'admin.users',
    path: root + '/users',
    label: i18n.users,
  },
  patients: {
    route: 'admin.patients',
    path: root + '/patients',
    label: i18n.patients,
  },
  appointments: {
    route: 'admin.appointments',
    path: root + '/steps',
    label: i18n.appointments,
  },
  externalTransports: {
    route: 'admin.externalTransports',
    path: root + '/external-transport',
    label: i18n.externalTransports,
  },
  healthcareEnvironmentalCleaning: {
    route: 'admin.healthcareEnvironmentalCleaning',
    path: root + '/healthcare-environmental-cleaning',
    label: i18n.healthcareEnvironmentalCleaning,
  },
  anomalies: {
    route: 'admin.anomalies',
    path: root + '/anomalies',
    label: i18n.anomalies,
  },
  emergencies: {
    route: 'admin.emergencies',
    path: root + '/emergencies',
    label: i18n.emergencies,
  },
  thesaurus: {
    route: 'admin.thesaurus',
    path: root + '/thesaurus',
    label: i18n.thesaurus,
  },
  overview: {
    route: 'admin.overview',
    path: root + '/overview',
    label: i18n.overview,
  },
  practitioners: {
    route: 'admin.practitioners',
    path: root + '/practitioners',
    label: i18n.practitioners,
  },
};

export const findPathByRoute = (route: AdminNavigationRoute) => {
  const found = Object.values(routes).find((r) => r.route === route);
  return found ? found.path : '';
};

export default routes;
