import Api, { Accommodation, Anomaly, Area, HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';

const getAccommodationsQuery = (beds: { id: string }[], start: Date, end: Date) =>
  new Api.Query(Accommodation)
    .greaterThan('endAt', start)
    .notEqualTo('status', 'CANCELLED')
    .lessThan('startAt', end)
    .containedIn(
      'bed',
      beds.map(({ id }) => Area.createWithoutData(id))
    )
    .include('bed', 'visit', 'practitioners');

const getHealthcareEnvrionmentalCleaningQuery = (beds: { id: string }[], start: Date, end: Date) =>
  new Api.Query(HealthcareEnvironmentalCleaning)
    .greaterThanOrEqualTo('endAt', start)
    .notEqualTo('status', 'CANCELED')
    .lessThanOrEqualTo('startAt', end)
    .containedIn(
      'location',
      beds.map(({ id }) => Area.createWithoutData(id))
    )
    .include('location');

const getAnomaliesQuery = (beds: { id: string }[], start: Date, end: Date) =>
  Api.Query.or(
    new Api.Query(Anomaly)
      .exists('endAt')
      .greaterThan('endAt', start)
      .lessThan('startAt', end)
      .equalTo('status', 'OPENED')
      .containedIn(
        'area',
        beds.map(({ id }) => Area.createWithoutData(id))
      ),
    new Api.Query(Anomaly)
      .doesNotExist('endAt')
      .lessThan('startAt', end)
      .equalTo('status', 'OPENED')
      .containedIn(
        'area',
        beds.map(({ id }) => Area.createWithoutData(id))
      )
  ).include('accommodation', 'accommodation.visit', 'accommodation.practitioners');

export { getAccommodationsQuery, getAnomaliesQuery, getHealthcareEnvrionmentalCleaningQuery };
