import Api, { Practitioner, Unit } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

export type PractitionerQueryParams = {
  unitIds: string[];
};

type UsePractitionersParams = PractitionerQueryParams & { enabled?: boolean };

const usePractitioners = ({ unitIds, enabled = false }: UsePractitionersParams) => {
  const {
    results: practitioners,
    isLoading: isPractitionersQueryLoading,
    refetch,
  } = useParseQuery(getPractitionersQuery({ unitIds }), {
    enabled,
  });

  return { practitioners, loading: isPractitionersQueryLoading, refetch };
};

const getPractitionersQuery = ({ unitIds }: PractitionerQueryParams) =>
  new Api.Query(Practitioner).containedIn(
    'unit',
    unitIds.map((id) => Unit.createWithoutData(id))
  );

export default usePractitioners;
