import { IconButton, Link, Stack, ToggleButton, Typography, useMediaQuery } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { AccommodationSpecificitiesSelect, PatientGenderSelect, ToggleGroup } from 'common/components';
import { Kanban, List, Sheets } from 'common/components/Icons';
import ExpandIcon from 'common/components/Icons/Expand';
import { PractitionerQueryParams, PractitionersFilter } from 'common/components/Practitioners';
import { i18n } from 'common/locale';
import { color, palette, theme } from 'common/theme';
import { trackEvent } from 'core/analytics/trackEvent';
import { useAppBarContext } from 'core/layout';
import { isValid } from 'date-fns';
import { DisabledViewTooltip } from 'kurt/components';
import FilterSection, { FilterSectionItem } from 'kurt/components/FilterSection';
import { SearchPatient } from 'kurt/components/SearchPatient';
import { useEffect } from 'react';
import { MovementsFilter, ViewType } from '../useMovementsPageSearchParams';
import MovementStatusSelect from './MovementStatusSelect';
import MovementTypeSelect from './MovementTypeSelect';

type MovementsFiltersProps = {
  flow: 'admission' | 'discharge';
  filter: MovementsFilter;
  onFilterChange: (filter: MovementsFilter) => void;
  onFlowChange: (flow: 'admission' | 'discharge') => void;
  accommodationCount?: number;
  practitionerQueryParams: PractitionerQueryParams;
  isMulti?: boolean;
};

const MovementsFilters: React.FC<MovementsFiltersProps> = ({
  filter,
  flow,
  onFilterChange,
  onFlowChange,
  accommodationCount = 0,
  practitionerQueryParams,
  isMulti = false,
}) => {
  const { setFullScreen } = useAppBarContext();
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const hasActiveFilters =
    filter.specificities.length > 0 ||
    filter.genders.length > 0 ||
    filter.search ||
    filter.movementTypes.length > 0 ||
    (filter.view === 'list' && filter.movementStatuses.length > 0) ||
    filter.practitioners.length > 0;

  const resetFilters = () => {
    onFilterChange({
      genders: [],
      specificities: [],
      date: new Date(),
      view: isMulti ? (['list', 'sheets'].includes(filter.view) ? filter.view : 'list') : filter.view,
      search: '',
      movementTypes: [],
      movementStatuses: [],
      practitioners: [],
    });
  };

  const handleDateChange = (value: Date | null) => {
    if (value && isValid(value)) {
      onFilterChange({ ...filter, date: value });
      trackEvent({
        event_type: 'movement_page_date_picker_date_changed',
        event_properties: {
          value,
        },
      });
    }
  };

  const handleKeyboardDateChange = (value: Date | null, context: FieldChangeHandlerContext<unknown>) => {
    if (!context.validationError) {
      handleDateChange(value);
    }
  };

  useEffect(() => {
    if (isMulti && filter.view === 'kanban') {
      onFilterChange({ ...filter, view: 'list' });
    }
  }, [isMulti, filter, onFilterChange]);

  return (
    <FilterSection>
      <FilterSectionItem.Filters>
        {filter.view !== 'sheets' && (
          <Stack direction="row" gap={4} flexWrap="wrap">
            <SearchPatient value={filter.search} onChange={(search) => onFilterChange({ ...filter, search })} />
            <AccommodationSpecificitiesSelect
              value={filter.specificities}
              onChange={(specificities) => onFilterChange({ ...filter, specificities })}
            />
            <PatientGenderSelect
              value={filter.genders}
              onChange={(genders) => onFilterChange({ ...filter, genders })}
            />
            <MovementTypeSelect
              flow={flow}
              onChange={(movementTypes) => onFilterChange({ ...filter, movementTypes })}
              value={filter.movementTypes}
            />
            {filter.view === 'list' && (
              <MovementStatusSelect
                flow={flow}
                onChange={(movementStatuses) => onFilterChange({ ...filter, movementStatuses })}
                value={filter.movementStatuses}
              />
            )}
            <PractitionersFilter
              practitionerQueryParams={practitionerQueryParams}
              value={filter.practitioners}
              onChange={(practitioners) => onFilterChange({ ...filter, practitioners })}
            />
            {hasActiveFilters && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Link component="button" onClick={resetFilters}>
                  <Typography color={color.grey[80]}>{i18n.clearFilters}</Typography>
                </Link>
                <Typography color="secondary">{i18n.visitCount(accommodationCount)}</Typography>
              </Stack>
            )}
          </Stack>
        )}
      </FilterSectionItem.Filters>
      <FilterSectionItem.Switchers>
        {filter.view !== 'sheets' && (
          <ToggleGroup value={flow} onChange={(_, value: 'admission' | 'discharge') => onFlowChange(value)}>
            <ToggleButton value="admission">{i18n.admissions}</ToggleButton>
            <ToggleButton value="discharge">{i18n.discharges}</ToggleButton>
          </ToggleGroup>
        )}
        <Stack gap={2} direction="row" alignItems="center">
          {matchUpLg && <Typography color={palette.primary.main}>{i18n.movementView[filter.view]}</Typography>}
          <ToggleGroup
            value={filter.view}
            onChange={(_, view) => onFilterChange({ ...filter, view: view as ViewType })}
          >
            <ToggleButton value="sheets">
              <Sheets />
            </ToggleButton>
            {isMulti ? (
              <DisabledViewTooltip>
                <Kanban />
              </DisabledViewTooltip>
            ) : (
              <ToggleButton value="kanban">
                <Kanban />
              </ToggleButton>
            )}
            <ToggleButton value="list">
              <List />
            </ToggleButton>
          </ToggleGroup>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          {matchUpLg && (
            <Typography variant="body1" color="secondary">
              {i18n.date}
            </Typography>
          )}
          <DatePicker
            value={filter.date}
            onChange={handleDateChange}
            slotProps={{
              textField: {
                sx: {
                  maxWidth: 134,
                },
              },
              field: {
                onChange: handleKeyboardDateChange,
              },
            }}
          />
        </Stack>

        <IconButton variant="outlined" size="large" shape="rounded" onClick={() => setFullScreen(true)}>
          <ExpandIcon />
        </IconButton>
      </FilterSectionItem.Switchers>
    </FilterSection>
  );
};

export default MovementsFilters;
