import {
  Accommodation,
  AccommodationRequest,
  Area,
  Cloud,
  ExternalPatientTransport,
  Fare,
  HealthcareEnvironmentalCleaning,
  Patient,
  maxDate,
} from '@ambuliz/sabri-core';
import { Emoji } from 'common/components';
import useParseResource from 'common/hooks/useParseResource';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useUnitFromBed } from 'core/locations';
import { subMinutes } from 'date-fns';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { AccommodationAction } from '../../PatientFlowKanban/VisitActions';
import { Step, StepItem, StepType } from './Step';
import { stepsMapper } from './stepsMapper';
import useAccommodationRequests from './useAccommodationRequests';
import useAccommodations from './useAccommodations';
import useAnomalies from './useAnomalies';
import useArea from './useArea';
import { useExternalPatientTransports } from './useExternalPatientTransports';
import useFares from './useFares';
import useHealthcareEnvironmentalCleanings from './useHealthcareEnvironmentalCleanings';

type DialogContextProviderProps = {
  children: React.ReactNode;
  id: string;
  type: StepType;
  visitId?: string;
  locationId?: string;
};

const getStepType = (step: Step): StepType => {
  if (step instanceof Accommodation) {
    return step.visit ? 'accommodation' : 'closedBed';
  }

  if (step instanceof AccommodationRequest) {
    return 'mutation';
  }

  if (step instanceof HealthcareEnvironmentalCleaning) {
    return 'healthCareEnvironmentalCleaning';
  }

  if (step instanceof Fare) {
    return 'fare';
  }

  if (step instanceof Area) {
    return 'availableBed';
  }

  if (step instanceof ExternalPatientTransport) {
    return 'externalTransport';
  }

  return 'accommodation';
};

type DialogContextProps = {
  stepItems: StepItem[];
  isLoading: boolean;
  initialStep?: StepItem;
  editableStep?: StepItem;
  currentStep?: StepItem;
  onCurrentStepChange: (id: string) => void;
  onStepChange: (stepValues: { step?: Step; destination?: Accommodation }) => void;
  toggleEditMode: (editableStep?: StepItem) => void;
  submitEditableStep: () => Promise<void>;
  editModeLoading: boolean;
  visit?: Patient;
  editMode: boolean;
  action: AccommodationAction | undefined;
  setAction: (action: AccommodationAction) => void;
  isDirty: () => boolean;
  cancelAction: () => void;
};

const DialogContext = createContext<DialogContextProps>({} as DialogContextProps);

const DialogContextProvider = ({ children, id, type, visitId, locationId }: DialogContextProviderProps) => {
  const queryParams = useMemo(
    () => (['accommodation', 'mutation'].includes(type) ? { visitId } : { locationId }),
    [type, visitId, locationId]
  );

  const [isLoading, setIsLoading] = useState(true);

  const [initialStep, setInitialStep] = useState<StepItem | undefined>();
  const [currentStep, setCurrentStep] = useState<StepItem | undefined>();
  const { resource: initialVisit } = useParseResource(visitId, Patient);
  const [visit, setVisit] = useState<Patient | undefined>(initialVisit);

  const [editableStep, setEditableStep] = useState<StepItem | undefined>(undefined);
  const [editingStepField, setEditingStepField] = useState<Set<'step' | 'destination'>>(new Set());
  const [editModeLoading, setEditModeLoading] = useState(false);

  const [action, setAction] = useState<AccommodationAction | undefined>(undefined);

  const { unit } = useUnitFromBed(initialStep?.type === 'availableBed' ? initialStep.step : undefined);

  const { accommodations: initialAccommodations } = useAccommodations(queryParams);
  const [accommodations, setAccommodations] = useState<Accommodation[]>(initialAccommodations);

  const { accommodationRequests: initialAccommodationRequests } = useAccommodationRequests(queryParams);
  const [accommodationRequests, setAccommodationRequests] =
    useState<AccommodationRequest[]>(initialAccommodationRequests);
  const { fares } = useFares({ visitId });

  const { externalTransports } = useExternalPatientTransports({
    visit,
  });

  const types = useMemo(() => {
    const anomalyTypes = ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END', 'OUTDATED_START'];
    if (locationId) {
      anomalyTypes.push('OVERLAPPING_STEPS');
    }

    return anomalyTypes;
  }, [locationId]);
  const { anomalies } = useAnomalies({ accommodations, types });

  const { healthcareEnvironmentalCleanings } = useHealthcareEnvironmentalCleanings({
    locationId,
  });

  const { area } = useArea({ locationId, enabled: type === 'availableBed' });

  const steps = useMemo(() => {
    const accommodationSteps = accommodations
      .filter((accommodation) => !!accommodation.visit)
      .map((accommodation) => stepsMapper.accommodation(accommodation, anomalies, visit));
    const closedBedSteps = accommodations
      .filter((accommodation) => !accommodation.visit)
      .map((closedBed) => stepsMapper.closedBed(closedBed, anomalies));
    const mutationSteps = accommodationRequests.map((mutation) =>
      stepsMapper.mutation(mutation, accommodations, visit)
    );
    const externalTransportSteps = externalTransports.map((externalTransport) =>
      stepsMapper.externalTransport(externalTransport)
    );
    const steps = [...fares, ...healthcareEnvironmentalCleanings];
    const stepItems: StepItem[] = [
      ...accommodationSteps,
      ...closedBedSteps,
      ...mutationSteps,
      ...externalTransportSteps,
    ];

    for (const step of steps) {
      const stepItem: StepItem = {
        type: getStepType(step),
        step,
      } as StepItem;

      switch (stepItem.type) {
        case 'healthCareEnvironmentalCleaning':
          stepItem.start = stepItem.step.startAt;
          stepItem.end = stepItem.step.endAt;
          stepItem.anomalies = anomalies.filter((anomaly) => anomaly.area?.id === stepItem.step.location.id);
          break;

        case 'fare':
          stepItem.start =
            stepItem.step.startedAt ||
            subMinutes(
              stepItem.step.wantedDate ||
                stepItem.step.scheduledAt ||
                stepItem.step.endedAt ||
                (stepItem.step.isEmergency ? stepItem.step.createdAt : new Date()),
              10
            );
          stepItem.end = stepItem.step.wantedDate || stepItem.step.scheduledAt || stepItem.step.endedAt || new Date();
          break;
      }

      stepItems.push(stepItem);
    }

    mutationSteps.forEach((mutationStep) => {
      if (mutationStep.origin) {
        const originStep = accommodationSteps.find(
          (accommodationStep) => accommodationStep.step.id === mutationStep.origin?.id
        );

        if (originStep) {
          originStep.destination = mutationStep.destination;
          originStep.mutation = mutationStep.step;
        }
      }
    });

    if (area) {
      stepItems.push(stepsMapper.availableBed(area, stepItems, unit));
    }

    return stepItems;
  }, [
    accommodations,
    accommodationRequests,
    fares,
    anomalies,
    healthcareEnvironmentalCleanings,
    externalTransports,
    area,
    unit,
    visit,
  ]);

  const isDirty = useCallback(() => {
    if (!editableStep?.step.id) {
      return false;
    }

    try {
      if (
        ((currentStep?.type === 'accommodation' && editableStep.type === 'accommodation') ||
          (currentStep?.type === 'mutation' && editableStep.type === 'mutation')) &&
        JSON.stringify(currentStep.step.visit) !== JSON.stringify(editableStep.step.visit)
      ) {
        return true;
      }
      return JSON.stringify(currentStep) !== JSON.stringify(editableStep);
    } catch (error) {
      return false;
    }
  }, [currentStep, editableStep]);

  useEffect(() => {
    const step = steps.find((step) => step.step.id === id);

    setInitialStep(step);
    if (step) {
      setIsLoading(false);
    }
    setCurrentStep((prev) => {
      return steps.find((step) => step.step.id === prev?.step.id) || step;
    });
  }, [id, steps]);

  const onCurrentStepChange = useCallback(
    (id: string) => {
      setCurrentStep(steps.find((step) => step.step.id === id));
    },
    [steps]
  );

  const onStepChange = useCallback(({ step, destination }: { step?: Step; destination?: Accommodation }) => {
    if (!step && !destination) return;

    setEditableStep((editableStep) => {
      switch (editableStep?.type) {
        case 'accommodation':
          return {
            ...editableStep,
            step: (step as Accommodation | undefined) || editableStep.step,
            destination: destination || editableStep.destination,
          };
        case 'closedBed':
          return {
            ...editableStep,
            step: (step as Accommodation | undefined) || editableStep.step,
          };
        case 'mutation':
          return {
            ...editableStep,
            step: (step as AccommodationRequest | undefined) || editableStep.step,
            destination: destination || editableStep.destination,
          };
      }

      return editableStep;
    });
    setEditingStepField((editingStepField) => {
      if (step) editingStepField.add('step');
      if (destination) editingStepField.add('destination');

      return editingStepField;
    });
  }, []);

  const toggleEditMode = useCallback((editableStep?: StepItem) => {
    setEditableStep((step) => (step ? undefined : editableStep));
    setEditingStepField(new Set());
  }, []);

  const submitEditableStep = useCallback(async () => {
    if (!editableStep || !initialStep) return;
    setEditModeLoading(true);

    try {
      let updateResult: Accommodation | AccommodationRequest | undefined = undefined;

      // Sorted to prevent to save the movement before the accommodation
      // Otherwise, the movement comment will be overwritten by the accommodation comment
      for (const field of Array.from(editingStepField).sort((a) => (a === 'step' ? 1 : -1))) {
        if (field === 'step') {
          updateResult = await updateStep(editableStep.step);
        } else if (editableStep[field as keyof StepItem]) {
          updateResult = await updateStep(editableStep[field as keyof StepItem] as Step);
        }
      }

      // Update ressources while waiting live queries to update
      if (editableStep.type === 'accommodation' && updateResult instanceof Accommodation) {
        setAccommodations((prevAccommodations) =>
          prevAccommodations.map((prev) => (prev.id === updateResult?.id ? (updateResult as Accommodation) : prev))
        );
        setCurrentStep(stepsMapper.accommodation(updateResult, anomalies));
      } else if (editableStep.type === 'mutation' && updateResult instanceof AccommodationRequest) {
        setAccommodationRequests((prevAccommodationRequests) =>
          prevAccommodationRequests.map((prev) =>
            prev.id === updateResult?.id ? (updateResult as AccommodationRequest) : prev
          )
        );
        setCurrentStep(stepsMapper.mutation(updateResult, accommodations));
      }
      if (updateResult?.visit?.isDataAvailable() && updateResult.visit.id === visit?.id) {
        setVisit(updateResult.visit);
      }

      // Disable loading mode
      setEditableStep(undefined);
      setEditingStepField(new Set());
      setEditModeLoading(false);

      toast.success({
        title: i18n.detailsDialog.toasts.saveSuccess.title,
        icon: <Emoji name="pencil" />,
        message: i18n.detailsDialog.toasts.saveSuccess.content,
      });
    } catch (err) {
      if (err?.message) {
        toast.error({
          title: i18n.detailsDialog.toasts.saveError.title,
          icon: <Emoji name="faceWithColdSweat" />,
          message: i18n.detailsDialog.toasts.saveError.content,
        });
      }
      setEditModeLoading(false);
    }
  }, [editableStep, initialStep, editingStepField, accommodations, anomalies, visit?.id]);

  useEffect(() => {
    setVisit(initialVisit);
  }, [initialVisit]);
  useEffect(() => {
    setAccommodations(initialAccommodations);
  }, [initialAccommodations]);
  useEffect(() => {
    setAccommodationRequests(initialAccommodationRequests);
  }, [initialAccommodationRequests]);

  const cancelAction = useCallback(() => {
    setAction(undefined);
  }, []);

  return (
    <DialogContext.Provider
      value={{
        stepItems: steps,
        initialStep,
        visit,
        isLoading,
        editableStep,
        currentStep,
        onCurrentStepChange,
        toggleEditMode,
        submitEditableStep,
        editModeLoading,
        onStepChange,
        editMode: !!editableStep,
        setAction,
        isDirty,
        action,
        cancelAction,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const getTimelineType = (step?: StepItem) => {
  switch (step?.type) {
    case 'accommodation':
      return 'patient';
    case 'mutation':
      return 'patient';
    case 'healthCareEnvironmentalCleaning':
      return 'location';
    case 'closedBed':
      return 'location';
    case 'fare':
      return 'patient';
    case 'availableBed':
      return 'location';
  }
};

const updateStep = async (step: Step): Promise<Accommodation | AccommodationRequest | undefined> => {
  switch (step.className) {
    case 'Accommodation':
      return updateAccommodation(step as Accommodation);

    case 'AccommodationRequest':
      return updateAccommodationRequest(step as AccommodationRequest);
  }
};

const updateAccommodation = async (accommodation: Accommodation): Promise<Accommodation> => {
  const queries: [Promise<Cloud.UpsertAccommodationResponse>, Promise<Cloud.UpdateVisitResponse> | undefined] = [
    Cloud.upsertAccommodation({
      accommodationId: accommodation.id,
      startAt: accommodation.startAt,
      endAt: accommodation.endAt || maxDate,
      isEndEstimated: !!accommodation.isEstimatedEnd,
      specificities: accommodation.specificities,
      comment: accommodation.comment,
      reason: accommodation.reason,
      thesaurusItemId: accommodation.thesaurusItem?.id || null,
      bedId: accommodation.bed?.id,
      unitId: accommodation.unit?.id,
      visitId: accommodation.visit?.id,
      practitionerIds: accommodation.practitioners?.map((practitioner) => practitioner.id),
    }),
    accommodation.visit ? updateVisit(accommodation.visit) : undefined,
  ];
  const result = await Promise.all(queries);
  const accommodationResult = result[0].accommodation;

  // Reassign all properties that are populated
  accommodationResult.visit = result[1]?.visit;
  accommodationResult.bed = accommodation.bed;
  accommodationResult.unit = accommodation.unit;
  accommodationResult.responsibleUnit = accommodation.responsibleUnit;
  accommodationResult.thesaurusItem = accommodation.thesaurusItem;
  return accommodationResult;
};

const updateAccommodationRequest = async (mutation: AccommodationRequest): Promise<AccommodationRequest> => {
  const queries: [Promise<AccommodationRequest>, Promise<Cloud.UpdateVisitResponse> | undefined] = [
    Cloud.updateAccommodationRequest({
      id: mutation.id,
      comment: mutation.comment,
      directAdmissionOrigin: mutation.directAdmissionOrigin,
      specificities: mutation.specificities,
      reason: mutation.reason,
      thesaurusItemId: mutation.thesaurusItem?.id || null,
    }),
    mutation.visit ? updateVisit(mutation.visit) : undefined,
  ];
  const result = await Promise.all(queries);
  const mutationResult = result[0];

  mutationResult.visit = result[1]?.visit;
  mutationResult.performerRequests = mutation.performerRequests;
  mutationResult.thesaurusItem = mutation.thesaurusItem;
  return mutationResult;
};

const updateVisit = (visit: Patient) => {
  return Cloud.updateVisit({
    visitId: visit.id,
    patientOutcome: {
      type: visit.patientOutcome?.type || null,
      comment: visit.patientOutcome?.comment,
    },
  });
};

export { DialogContext, DialogContextProvider };
