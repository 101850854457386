import Api, {
  Accommodation,
  AccommodationRequest,
  AccommodationSpecificity,
  Patient,
  PatientGender,
  Unit,
} from '@ambuliz/sabri-core';
import { PRACTITIONER_NOT_DEFINED_VALUE } from 'common/components/Practitioners/PractitionersFilter';
import useParseQuery from 'common/hooks/useParseQuery';
import { Order } from 'common/types';
import { filterBySearch } from 'common/utils';
import { startOfDay, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { comparePatients, compareValues, getCurrentPerformerRequest, useEveryMinutes } from '../utils';

export type PresentPatient = {
  id: string;
  patient: Patient;
  accommodation: Accommodation;
  accommodationRequest?: AccommodationRequest;
};

export type PresentPatientsOrderBy = 'startAt' | 'patient' | 'age' | 'bed';

export type PresentPatientsOrderParams = {
  orderBy: PresentPatientsOrderBy;
  order: Order;
};

type FilterParams = {
  search?: string;
  specificities?: AccommodationSpecificity[];
  genders?: PatientGender[];
  practitioners?: string[];
};

type PaginationParams = {
  page: number;
  rowsPerPage: number;
};

type UsePresentPatientsParams = PresentPatientsOrderParams & FilterParams & PaginationParams;

const usePresentPatients = (
  unitId: string,
  {
    orderBy,
    order,
    search,
    specificities = [],
    genders = [],
    practitioners = [],
    page,
    rowsPerPage,
  }: UsePresentPatientsParams
) => {
  const now = useEveryMinutes();

  const [presentPatients, setPresentPatients] = useState<PresentPatient[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const { results, isLoading: accommodationsLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .equalTo('unit', Unit.createWithoutData(unitId))
      .equalTo('status', 'IN_PROGRESS')
      .greaterThanOrEqualTo('startAt', startOfDay(subDays(now, 15)))
      .lessThanOrEqualTo('startAt', now)
      .exists('patient')
      .exists('visit')
      .include('visit', 'bed', 'practitioners')
  );

  const visits = results.map((accommodation) => accommodation.visit) as Patient[];

  const { results: accommodationRequests, isLoading: requestsLoading } = useParseQuery(
    new Api.Query(AccommodationRequest).containedIn('visit', visits).include('performerRequests'),
    { enabled: !!visits.length }
  );

  const isLoading = accommodationsLoading || requestsLoading;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    let presentPatients: PresentPatient[] = results.map((accommodation) => {
      const accommodationRequest = accommodationRequests.find(
        ({ visit, performerRequests }) =>
          visit?.id === accommodation.visit?.id &&
          getCurrentPerformerRequest(performerRequests || [])?.performer.id !== unitId
      );

      return {
        id: accommodation.id,
        patient: accommodation.visit!,
        accommodation,
        accommodationRequest,
      };
    });

    presentPatients = filterResults(presentPatients, { search, specificities, genders, practitioners });

    presentPatients = sortResults(presentPatients, { orderBy, order });

    setTotalCount(presentPatients.length);
    setPresentPatients(presentPatients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    setLoading(false);
  }, [
    accommodationRequests,
    search,
    genders,
    practitioners,
    isLoading,
    order,
    orderBy,
    page,
    results,
    rowsPerPage,
    specificities,
    unitId,
  ]);

  return { presentPatients, totalCount, loading };
};

const sortResults = (results: PresentPatient[], { orderBy, order }: PresentPatientsOrderParams) => {
  return results.sort((a, b) => {
    switch (orderBy) {
      case 'startAt':
        return compareValues(a.accommodation.startAt, b.accommodation.startAt, order);
      case 'patient':
        return comparePatients(a.patient, b.patient, order);
      case 'age':
        return compareValues(a.patient.birthdate, b.patient.birthdate, order === 'asc' ? 'desc' : 'asc');
      case 'bed':
        return compareValues(a.accommodation.bed?.name, b.accommodation.bed?.name, order);
      default:
        return 0;
    }
  });
};

const filterResults = (results: PresentPatient[], { search, specificities, genders, practitioners }: FilterParams) => {
  const presentPatients: PresentPatient[] = [];

  if (search && search.length > 0) {
    results = filterBySearch({
      search,
      list: results,
      keys: ['patient.firstName', 'patient.lastName', 'patient.legalName', 'patient.legalFirstName'],
      minScore: 0.5,
    });
  }

  for (const presentPatient of results) {
    if (specificities && specificities.length > 0) {
      if (!specificities.some((specificity) => presentPatient.accommodation.specificities?.includes(specificity))) {
        continue;
      }
    }
    if (genders && genders.length > 0) {
      if (!genders.includes(presentPatient.patient.gender)) {
        continue;
      }
    }
    if (practitioners && practitioners.length > 0) {
      if (
        practitioners.includes(PRACTITIONER_NOT_DEFINED_VALUE) &&
        (!presentPatient.accommodation.practitioners || presentPatient.accommodation.practitioners.length === 0)
      ) {
        presentPatients.push(presentPatient);
        continue;
      }

      if (
        !presentPatient.accommodation.practitioners?.some((practitioner) => practitioners.includes(practitioner.id))
      ) {
        continue;
      }
    }
    presentPatients.push(presentPatient);
  }

  return presentPatients;
};

export default usePresentPatients;
