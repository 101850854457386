import { Cloud } from '@ambuliz/sabri-core';
import { PaletteOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, Divider, Stack, TextField, Typography } from '@mui/material';
import { DialogHeader } from 'common/components';
import UserIcon from 'common/components/Icons/UsersOutline';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ColorRadioButton, { radioButtonColorNames, radioButtonColors } from './ColorRadioButton';

type CreatePractitionerDialogProps = {
  unitId: string;
};

const CreatePractitionerDialog = ({ unitId }: CreatePractitionerDialogProps) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const navigate = useNavigate();

  const handleClose = (refresh = false) => {
    setOpen(false);
    setShouldRefresh(refresh);
  };

  const handleRedirect = () =>
    navigate('..', {
      state: {
        refresh: shouldRefresh,
      },
    });

  const handleSubmit = async (practitioner: CreatePractitionerFormState) => {
    setLoading(true);
    try {
      await Cloud.createPractitioner({
        firstName: practitioner.firstName,
        lastName: practitioner.lastName,
        color: practitioner.color,
        unitId,
      });
      toast.success(i18n.createPractitionerDialog.successToast);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
      handleClose(true);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth="md" onTransitionExited={handleRedirect}>
      <DialogHeader onClose={() => handleClose()} title={i18n.createPractitionerDialog.title} />
      <CreatePractitionerForm loading={loading} onClose={() => handleClose()} onSubmit={handleSubmit} />
    </Dialog>
  );
};

type CreatePractitionerFormState = {
  firstName: string;
  lastName: string;
  color: string;
};

type CreatePractitionerFormProps = {
  loading?: boolean;
  onClose: () => void;
  onSubmit: (practitioner: CreatePractitionerFormState) => void;
};

const CreatePractitionerForm = ({ loading, onClose, onSubmit }: CreatePractitionerFormProps) => {
  const [practitioner, setPractitioner] = useState<CreatePractitionerFormState>(defaultPractitioner);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (practitioner) {
      onSubmit(practitioner);
    }
  };

  const handleChange = (key: keyof CreatePractitionerFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPractitioner((prev) => ({ ...prev, [key]: event.target.value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Stack divider={<Divider orientation="horizontal" style={{ marginLeft: 32 }} />}>
          <Stack direction="row" padding={3} gap={1} alignItems="center">
            <Stack direction="row" alignItems="center" gap={2} minWidth={190}>
              <UserIcon sx={{ fontSize: 12 }} color="secondary" />
              <Typography fontWeight={600}>{i18n.createPractitionerDialog.nameInputLabel}</Typography>
            </Stack>
            <Stack direction="row" gap={2} flex={1}>
              <TextField
                variant="filled"
                size="small"
                placeholder={i18n.createPractitionerDialog.lastName}
                value={practitioner.lastName}
                onChange={handleChange('lastName')}
                sx={{
                  flex: 1,
                }}
              />
              <TextField
                variant="filled"
                size="small"
                placeholder={i18n.createPractitionerDialog.firstName}
                value={practitioner.firstName}
                onChange={handleChange('firstName')}
                sx={{
                  flex: 1,
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" padding={3} gap={1} alignItems="center">
            <Stack direction="row" alignItems="center" gap={2} minWidth={190}>
              <PaletteOutlined sx={{ fontSize: 12 }} color="secondary" />
              <Typography fontWeight={600}>{i18n.createPractitionerDialog.colorInputLabel}</Typography>
            </Stack>
            <Stack direction="row" gap={3} alignItems="center">
              {radioButtonColorNames.map((color) => (
                <ColorRadioButton
                  key={color}
                  color={color}
                  isSelected={practitioner.color === radioButtonColors[color]}
                  onChange={(event) => {
                    setPractitioner((prev) => ({ ...prev, color: event.target.value }));
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {i18n.cancel}
        </Button>
        <LoadingButton type="submit" loading={loading}>
          {i18n.createPractitionerDialog.submitButtonLabel}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const defaultPractitioner: CreatePractitionerFormState = {
  firstName: '',
  lastName: '',
  color: radioButtonColors.default,
};

export default CreatePractitionerDialog;
