import { Accommodation, DelayRule } from '@ambuliz/sabri-core';
import { PRACTITIONER_NOT_DEFINED_VALUE } from 'common/components/Practitioners/PractitionersFilter';
import { filterBySearch } from 'common/utils';
import { PATIENT_OUTCOME_NOT_DEFINED_VALUE } from 'kurt/components';
import { UnitManagementFilter } from '../UnitManagementFilters';
import { flatResource, getGender, getStatus } from './useResources.helpers';
import { Resource } from './useResources.types';

const filterResourceByGenders = (resource: Resource, genders: UnitManagementFilter['genders']) => {
  if (genders.length === 0) {
    return true;
  }
  const gender = getGender(resource);
  return genders.includes(gender);
};

const filterResourceByStatuses = (
  resource: Resource,
  statuses: UnitManagementFilter['statuses'],
  bookedBedDelayRule?: DelayRule
) => {
  if (statuses.length === 0) {
    return true;
  }
  const status = getStatus(resource, bookedBedDelayRule);
  return statuses.includes(status);
};

const filterResourceBySpecificities = (resource: Resource, specificities: UnitManagementFilter['specificities']) => {
  if (specificities.length === 0) {
    return true;
  }
  const now = new Date();
  return specificities.some(
    (specificity) =>
      resource.accommodation?.specificities?.includes(specificity) ||
      resource.accommodations.some(
        (accommodation) =>
          accommodation.startAt <= now &&
          (!accommodation.endAt || accommodation.endAt >= now) &&
          accommodation.specificities?.includes(specificity)
      )
  );
};

const filterResourceByPractitioners = (resource: Resource, practitioners: string[]) => {
  if (practitioners.length === 0) {
    return true;
  }

  const accommodationWithoutPractitioners =
    (resource.accommodation?.visit &&
      (!resource.accommodation.practitioners || resource.accommodation.practitioners.length === 0)) ||
    false;

  const accommodationWithPractitionersFiltered =
    resource.accommodation?.practitioners?.some((practitioner) => practitioners.includes(practitioner.id)) || false;

  if (practitioners.includes(PRACTITIONER_NOT_DEFINED_VALUE)) {
    if (practitioners.length === 1) {
      return accommodationWithoutPractitioners;
    }
    return accommodationWithoutPractitioners || accommodationWithPractitionersFiltered;
  }

  return accommodationWithPractitionersFiltered;
};

const accommodationMatchesPatientOutcomeFilter = (
  patientOutcomes: UnitManagementFilter['patientOutcomes'],
  accommodation?: Accommodation
) => {
  if (accommodation?.visit?.patientOutcome?.type) {
    return patientOutcomes.includes(accommodation.visit.patientOutcome.type);
  } else {
    return patientOutcomes.includes(PATIENT_OUTCOME_NOT_DEFINED_VALUE);
  }
};

const filterResourceByPatientOutcomes = (
  resource: Resource,
  patientOutcomes: UnitManagementFilter['patientOutcomes']
) => {
  if (patientOutcomes.length === 0) {
    return true;
  }
  return accommodationMatchesPatientOutcomeFilter(patientOutcomes, resource.accommodation);
};

const filterResource = (resource: Resource, filter: UnitManagementFilter, bookedBedDelayRule?: DelayRule) => {
  let isFiltered = true;

  isFiltered &&= filterResourceByGenders(resource, filter.genders);
  isFiltered &&= filterResourceByStatuses(resource, filter.statuses, bookedBedDelayRule);

  if (filter.view !== 'timeline') {
    isFiltered &&= filterResourceBySpecificities(resource, filter.specificities);
    isFiltered &&= filterResourceByPractitioners(resource, filter.practitioners);
    isFiltered &&= filterResourceByPatientOutcomes(resource, filter.patientOutcomes);
  }

  return isFiltered;
};

const filterResourcesBySearch = (resources: Resource[], filter: UnitManagementFilter) => {
  if (!filter.search) {
    return resources;
  }
  return filterBySearch({
    list: resources,
    search: filter.search,
    keys: [
      'accommodation.visit.firstName',
      'accommodation.visit.lastName',
      'accommodation.visit.legalName',
      'accommodation.visit.legalFirstName',
    ],
  });
};

const filterResourcesByPractitioners = (resources: Resource[], filter: UnitManagementFilter) => {
  if (filter.view !== 'timeline' || filter.practitioners.length === 0) {
    return resources;
  }

  return resources
    .map((resource) => {
      const accommodations = resource.accommodations.filter((accommodation) => {
        const accommodationWithoutPractitioners =
          accommodation.visit && (!accommodation.practitioners || accommodation.practitioners.length === 0);
        const accommodationWithPractitionersFiltered = accommodation.practitioners?.some((practitioner) =>
          filter.practitioners.includes(practitioner.id)
        );
        if (filter.practitioners.includes(PRACTITIONER_NOT_DEFINED_VALUE)) {
          return filter.practitioners.length === 1
            ? accommodationWithoutPractitioners
            : accommodationWithoutPractitioners || accommodationWithPractitionersFiltered;
        }
        return accommodationWithPractitionersFiltered;
      });

      return { ...resource, accommodations };
    })
    .filter((resource) => resource.accommodations.length > 0);
};

const filterResourcesByPatientOutcomes = (resources: Resource[], filter: UnitManagementFilter) => {
  if (filter.view !== 'timeline' || filter.patientOutcomes.length === 0) {
    return resources;
  }

  return resources
    .map((resource) => {
      const filteredAccommodations = resource.accommodations.filter((accommodation) =>
        accommodationMatchesPatientOutcomeFilter(filter.patientOutcomes, accommodation)
      );
      const filteredAccommodation = accommodationMatchesPatientOutcomeFilter(
        filter.patientOutcomes,
        resource.accommodation
      )
        ? resource.accommodation
        : undefined;
      return { ...resource, accommodations: filteredAccommodations, accommodation: filteredAccommodation };
    })
    .filter((resource) => resource.accommodation || resource.accommodations.length > 0);
};

const filterResourcesBySpecificities = (resources: Resource[], filter: UnitManagementFilter) => {
  if (filter.view !== 'timeline' || filter.specificities.length === 0) {
    return resources;
  }

  return resources
    .map((resource) => {
      const filteredAccommodations = resource.accommodations.filter((accommodation) =>
        filter.specificities.some((specificity) => accommodation.specificities?.includes(specificity))
      );
      const filteredAccommodation = filter.specificities.some((specificity) =>
        resource.accommodation?.specificities?.includes(specificity)
      )
        ? resource.accommodation
        : undefined;
      return { ...resource, accommodations: filteredAccommodations, accommodation: filteredAccommodation };
    })
    .filter((resource) => resource.accommodations.length > 0);
};

const filterResources = (resources: Resource[], filter: UnitManagementFilter, bookedBedDelayRule?: DelayRule) => {
  resources = filterResourcesBySearch(resources, filter);

  let filteredResources = resources.filter((resource) =>
    ['grid', 'timeline'].includes(filter.view)
      ? filterResource(resource, filter, bookedBedDelayRule)
      : flatResource(resource).some((resource) => filterResource(resource, filter, bookedBedDelayRule))
  );

  filteredResources = filterResourcesByPractitioners(filteredResources, filter);
  filteredResources = filterResourcesByPatientOutcomes(filteredResources, filter);
  filteredResources = filterResourcesBySpecificities(filteredResources, filter);

  return filteredResources;
};

export { filterResource, filterResources };
