import { NavigationACL, NavigationRoute, UserRole } from '@ambuliz/sabri-core';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { APP_ROUTES_TREE } from 'common/types/appRoutes';
import { useMemo } from 'react';

type NavigationAclSelectProps = {
  value: NavigationACL;
  onChange: (value: NavigationACL) => void;
  role?: UserRole;
};

const NavigationAclSelect = ({ value: acl = [], onChange: setACL, role = 'MANAGER' }: NavigationAclSelectProps) => {
  const pages = useMemo(() => {
    if (['ADMIN', 'ADMIN_REGULATOR'].includes(role)) {
      return APP_ROUTES_TREE;
    }

    return APP_ROUTES_TREE.filter((page) => page.parentRoute !== 'admin');
  }, [role]);

  const isChecked = (route: NavigationRoute) => {
    for (const page of acl) {
      if (page.length < route.length && route.startsWith(page)) {
        return true;
      }
    }

    return acl.includes(route);
  };

  const isIndeterminate = (route: NavigationRoute) => {
    if (acl.includes(route)) {
      return false;
    }
    for (const aclRoute of acl) {
      if (aclRoute.startsWith(route)) {
        return true;
      }
    }
  };

  const handleChange = (route: NavigationRoute) => () => {
    const parent = findParent(route);
    let newACL = acl;

    if (isChecked(route) && acl.includes(route)) {
      newACL = acl.filter((aclRoute) => !aclRoute.startsWith(route));
    } else if (isChecked(route) && !acl.includes(route)) {
      if (parent) {
        const newAcl = new Set(acl);
        for (const page of parent.children || []) {
          newAcl.add(page.route);
        }

        newAcl.delete(parent.parentRoute);
        newAcl.delete(route);

        newACL = Array.from(newAcl);
      }
    }

    if (!isChecked(route) && !acl.includes(route)) {
      const newAcl = new Set([...acl, route]);

      if (parent) {
        const includesAllChildren = parent.children?.every((page) => newAcl.has(page.route));

        if (includesAllChildren) {
          newAcl.add(parent.parentRoute);
          for (const page of parent.children || []) {
            newAcl.delete(page.route);
          }
        }
      }
      newACL = Array.from(newAcl);
    }

    if (newACL.includes(route)) {
      newACL = newACL.filter((acl) => {
        const isChild = acl.split('.').length > route.split('.').length && acl.startsWith(route);
        return !isChild;
      });
    }

    setACL(newACL);
  };

  return (
    <Stack spacing={1}>
      {pages.map((page) => {
        return (
          <Stack key={page.parentRoute}>
            <FormControlLabel
              key={page.parentRoute}
              label={page.label}
              control={
                <Checkbox
                  checked={isChecked(page.parentRoute)}
                  indeterminate={isIndeterminate(page.parentRoute)}
                  onChange={handleChange(page.parentRoute)}
                />
              }
            />
            {page.children?.map((subPage) => {
              return (
                <Box pl={4} key={subPage.route}>
                  <FormControlLabel
                    label={subPage.label}
                    control={
                      <Checkbox
                        checked={isChecked(subPage.route)}
                        indeterminate={isIndeterminate(subPage.route)}
                        onChange={handleChange(subPage.route)}
                      />
                    }
                  />
                </Box>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};

const findParent = (route: NavigationRoute) => {
  for (const tree of APP_ROUTES_TREE) {
    if (tree.children.some((child) => child.route === route)) {
      return tree;
    }
  }
};

export default NavigationAclSelect;
