import { Cloud, Practitioner } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DialogHeader } from 'common/components';
import useParseResource from 'common/hooks/useParseResource';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const RemovePractitionerDialog = () => {
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const { resource: practitioner, isLoading } = useParseResource(id, Practitioner);
  const [removeLoading, setRemoveLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = (refresh = false) => {
    setOpen(false);
    setShouldRefresh(refresh);
  };

  const handleRedirect = () =>
    navigate('..', {
      state: {
        refresh: shouldRefresh,
      },
    });

  const handleSubmit = async () => {
    if (!id) {
      return;
    }

    setRemoveLoading(true);
    try {
      await Cloud.removePractitioner({ id, ...practitioner });
      toast.success(i18n.removePractitionerDialog.successToast);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setRemoveLoading(false);
      handleClose(true);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth="md" onTransitionExited={handleRedirect}>
      {isLoading || !practitioner ? (
        <Stack m={4} alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <DialogHeader onClose={() => handleClose()} title={i18n.removePractitionerDialog.title} />
          <RemovePractitionerForm loading={removeLoading} onSubmit={handleSubmit} onClose={() => handleClose()} />
        </>
      )}
    </Dialog>
  );
};

type RemovePractitionerFormProps = {
  loading?: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

const RemovePractitionerForm = ({ loading, onSubmit, onClose }: RemovePractitionerFormProps) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Typography color="secondary">{i18n.removePractitionerDialog.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {i18n.cancel}
        </Button>
        <LoadingButton type="submit" loading={loading}>
          {i18n.removePractitionerDialog.submitButtonLabel}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default RemovePractitionerDialog;
