import { Practitioner } from '@ambuliz/sabri-core';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ListPagination } from 'common/components';
import { i18n } from 'common/locale';
import PractitionersListItem from './PractitionersListItem';

type PractitionersListProps = {
  practitioners: Practitioner[];
  loading?: boolean;
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
};

const PractitionersList = ({
  practitioners,
  count,
  page,
  rowsPerPage,
  loading,
  onPageChange,
  onRowsPerPageChange,
}: PractitionersListProps) => {
  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{i18n.practitionersPage.table.name}</TableCell>
              <TableCell>{i18n.practitionersPage.table.tag}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <CircularProgress size={16} />
                </TableCell>
              </TableRow>
            ) : (
              practitioners.map((practitioner) => (
                <PractitionersListItem key={practitioner.id} practitioner={practitioner} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ListPagination
        count={count}
        onPageChange={(_event, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={(event) => onRowsPerPageChange(parseInt(event.target.value, 10))}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={i18n.practitionersPage.table.practitionersPerPage}
      />
    </Paper>
  );
};

export default PractitionersList;
