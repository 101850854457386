import { Button } from '@mui/material';
import { useDebounce } from 'common/hooks';
import { useRefreshOnNavigate } from 'common/hooks/useRefreshOnNavigate';
import { PageHeader, PageSection } from 'core/layout';
import { useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { UnitResultList } from './components/UnitResultList';
import { UnitSearchFilter, UnitSearchFilters } from './components/UnitSearchFilters';
import { useUnitSearch } from './hooks/useUnitSearch';

const UnitManagement = () => {
  const navigate = useNavigate();

  const [params, setParams] = useState<UnitSearchFilter>({
    name: '',
    specialties: [],
    departments: [],
    types: [],
    page: 0,
    pageSize: 50,
  });

  const { units, unitsCount, isLoading, refetch } = useUnitSearch(useDebounce(params, 500));
  useRefreshOnNavigate(refetch);

  const handleCreateUnitClick = useCallback(() => navigate('create'), [navigate]);
  const handleUnitClick = useCallback((unitId: string) => navigate(unitId), [navigate]);

  return (
    <>
      <PageHeader title="Gestion des unités">
        <Button onClick={handleCreateUnitClick}>Créer une unité</Button>
      </PageHeader>
      <PageSection>
        <UnitSearchFilters count={unitsCount} isLoading={isLoading} params={params} onParamsChange={setParams} />
      </PageSection>
      <PageSection withBackground noGutter lastSection>
        <UnitResultList units={units} isLoading={isLoading} onUnitClick={handleUnitClick} />
      </PageSection>
      <Outlet />
    </>
  );
};

export default UnitManagement;
