import { Practitioner } from '@ambuliz/sabri-core';
import { Button, MenuItem } from '@mui/material';
import { FilterSelect } from 'common/components';
import PencilIcon from 'common/components/Icons/Pencil';
import PlusLightIcon from 'common/components/Icons/PlusLightIcon';
import { i18n } from 'common/locale';
import { PageHeader, PageSection, useAppBarContext } from 'core/layout';
import { Unit } from 'core/locations';
import FilterSection, { FilterSectionItem } from 'kurt/components/FilterSection';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CreatePractitionerDialog from './CreatePractitionerDialog';
import PractitionersList from './PractitionersList';
import RemovePractitionerDialog from './RemovePractitionerDialog';
import UpdatePractitionerDialog from './UpdatePractitionerDialog';

type PractitionersProps = {
  unitId: string;
  unitName: string;
  units: Unit[];
  practitioners: Practitioner[];
  count: number;
  page: number;
  rowsPerPage: number;
  displayUnitSelect?: boolean;
  loading?: boolean;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  onUnitChange: (unitId: string) => void;
};

const PractitionersPage = ({
  unitId,
  unitName,
  units,
  practitioners,
  displayUnitSelect,
  count,
  page,
  rowsPerPage,
  loading,
  onUnitChange,
  onPageChange,
  onRowsPerPageChange,
}: PractitionersProps) => {
  const { appBarHeight } = useAppBarContext();
  const navigate = useNavigate();

  const handleAddPractitionerClick = () => {
    navigate('./create');
  };

  return (
    <>
      <PageHeader
        title={
          displayUnitSelect ? `${i18n.practitionersPage.secondaryHeader} ${unitName}` : i18n.practitionersPage.header
        }
        subheader={
          displayUnitSelect
            ? `${i18n.practitionersPage.secondarySubheader} ${unitName}`
            : i18n.practitionersPage.subheader
        }
        SubheaderIcon={PencilIcon}
      >
        <Button onClick={handleAddPractitionerClick} size="large" startIcon={<PlusLightIcon />}>
          {i18n.practitionersPage.addPractitioner}
        </Button>
      </PageHeader>
      {displayUnitSelect && (
        <PageSection sticky={{ top: appBarHeight }}>
          <FilterSection>
            <FilterSectionItem.Filters>
              <FilterSelect label={i18n.unit} value={unitId} onChange={(unitId) => onUnitChange(unitId)}>
                {units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </FilterSelect>
            </FilterSectionItem.Filters>
          </FilterSection>
        </PageSection>
      )}
      <PageSection withBackground lastSection noGutter>
        <PractitionersList
          practitioners={practitioners}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          loading={loading}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </PageSection>

      <Routes>
        <Route path="/create" element={<CreatePractitionerDialog unitId={unitId} />} />
        <Route path="/:id/update" element={<UpdatePractitionerDialog />} />
        <Route path="/:id/remove" element={<RemovePractitionerDialog />} />
      </Routes>
    </>
  );
};

export default PractitionersPage;
