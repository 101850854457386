import { AccommodationRequest } from '@ambuliz/sabri-core';
import { Chip, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import { Comment, EntryDashed, Unit } from 'common/components/Icons';
import DoctorIcon from 'common/components/Icons/DoctorIcon';
import Emergency from 'common/components/Icons/Emergency';
import { i18n } from 'common/locale';
import { formatAge, formatDateTime, formatName, formatPractitionerName } from 'common/utils';
import { format } from 'date-fns';
import { AccommodationSpecificityBadges } from 'kurt/components';
import AdmissionStatusChip from 'kurt/components/StepStatus/AdmissionStatusChip';
import MutationStatusChip from 'kurt/components/StepStatus/MutationStatusChip';
import BedName from 'kurt/pages/Unit/BedName';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStatus } from '../hooks/getStatus';
import { OverviewResource } from '../hooks/useOverviewResources';

export type AccommodationResultRowProps = {
  resource: OverviewResource;
};

export const AccommodationResultRow = ({ resource }: AccommodationResultRowProps) => {
  const { accommodation, department, accommodationRequest, destination } = resource;
  const { visit } = accommodation;

  const navigation = useRef(useNavigate());
  const handleClick = useCallback((id: string, type: 'accommodation' | 'mutation') => {
    navigation.current(`${window.location.pathname}/${type}/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  const status = getStatus(accommodation);

  if (!visit) {
    return null;
  }

  return (
    <TableRow
      key={accommodation.id}
      onClick={() =>
        accommodationRequest
          ? handleClick(accommodationRequest.id, 'mutation')
          : handleClick(accommodation.id, 'accommodation')
      }
      hover
      sx={{
        cursor: 'pointer',
      }}
    >
      <TableCell>
        <Typography variant="body2" color="secondary">
          {department}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {accommodation.unit.name}
        </Typography>
      </TableCell>
      <TableCell>{accommodation.bed && <BedName name={accommodation.bed.name} />}</TableCell>
      <TableCell>{status && <AdmissionStatusChip status={status} />}</TableCell>
      <TableCell>
        <PatientNameWithEmoji
          name={formatName(visit.firstName, visit.lastName)}
          gender={visit.gender}
          birthdate={visit.birthdate}
        />
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {formatAge(visit.birthdate)}
        </Typography>
      </TableCell>
      <TableCell>
        <AccommodationSpecificityBadges specificities={accommodation.specificities} direction="row" />
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {accommodation.responsibleUnit?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="body2" color="secondary" style={{ minWidth: 30 }}>
            {accommodation.status === 'PLANNED' ? i18n.bedsOverview.prevShort : i18n.bedsOverview.effectiveShort}
          </Typography>
          <Typography variant="body2" color="secondary" fontWeight={600}>
            {formatDateTime(accommodation.startAt)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="body2" color="secondary" style={{ minWidth: 30 }}>
            {accommodation.isEstimatedEnd ? i18n.bedsOverview.prevShort : i18n.bedsOverview.confirmedShort}
          </Typography>
          <Typography variant="body2" color="secondary" fontWeight={600}>
            {accommodation.endAt ? formatDateTime(accommodation.endAt) : '-'}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {accommodationRequest && <MutationStatusChip status={getMutationStatus(accommodationRequest)} />}
      </TableCell>
      <TableCell>
        {accommodationRequest &&
          accommodationRequest.performerRequests?.map((performerRequest) => (
            <Stack direction="row" alignItems="center" gap={2}>
              {accommodation.unit.type === 'EMERGENCY' ? (
                <Emergency color="danger" />
              ) : accommodationRequest?.requestType === 'DIRECT_ADMISSION' ? (
                <EntryDashed color="tertiary" />
              ) : (
                <Unit color="info" fontSize="small" />
              )}
              <Typography variant="body2" color="secondary" style={{ marginLeft: 2 }}>
                {performerRequest.performer.name}
              </Typography>
              {destination?.bed && <Chip label={destination?.bed?.name} variant="rounded" size="x-small" />}
            </Stack>
          ))}
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary" fontWeight={600}>
          {destination?.startAt ? format(destination.startAt, 'HH:mm') : ''}
        </Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" justifyContent="right" gap={3}>
          {accommodation.comment && (
            <Tooltip placement="top" title={accommodation.comment}>
              <Stack>
                <Comment color="secondary" />
              </Stack>
            </Tooltip>
          )}
          {!!accommodation.practitioners?.length && (
            <Tooltip
              placement="top"
              title={`${i18n.bedsOverview.doctor}: ${accommodation.practitioners.map((p) => formatPractitionerName(p.firstName, p.lastName)).join(',')}`}
            >
              <Stack>
                <DoctorIcon color="secondary" />
              </Stack>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const getMutationStatus = (accommodationRequest: AccommodationRequest) => {
  const isToAddress = accommodationRequest.status === 'REQUESTED' && !accommodationRequest.performerRequests?.length;
  const isRequested = accommodationRequest.status === 'REQUESTED' && accommodationRequest.performerRequests?.length;
  const isAccepted = accommodationRequest.status === 'ACCEPTED';

  if (isRequested) {
    return 'PENDING';
  }
  if (isToAddress) {
    return 'TO_ADDRESS';
  }
  if (isAccepted) {
    return 'ACCEPTED';
  }

  return 'COMPLETED';
};
