import { Button, IconButton } from '@mui/material';
import { Plus } from 'common/components/Icons';
import CollapseIcon from 'common/components/Icons/Collapse';
import { PatientSearchResult } from 'common/components/PatientAutocomplete';
import { PractitionerQueryParams } from 'common/components/Practitioners';
import { i18n } from 'common/locale';
import { useReadOnly } from 'core/authentication';
import { PageContent, PageHeader, PageSection, PageTitle, useAppBarContext } from 'core/layout';
import { Unit } from 'core/locations';
import useNavigableUnits from 'core/locations/useNavigableUnits';
import { format, isToday } from 'date-fns';
import { fr } from 'date-fns/locale';
import { DetailsDialog } from 'kurt/components';
import CreateAdmissionDialog from 'kurt/components/CreateAdmissionDialog';
import SendAccommodationRequestDialog from 'kurt/components/SendAccommodationRequest';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, Route, Routes, useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import MovementsFilters from './Filters/MovementsFilters';
import MovementSheets from './MovementSheets';
import MovementsKanban from './MovementsKanban';
import MovementsList from './MovementsList';
import useMovementsPageAppBar from './useMovementsAppBar';
import useMovementsPageSearchParams, {
  FlowType,
  MovementsFilter,
  ViewType,
  getFilterFromParams,
} from './useMovementsPageSearchParams';

type MovementsPageProps = {
  units: Unit[];
  flow: FlowType;
};

type AddAccomodationModalState = {
  id?: string;
  startAt?: Date;
  endAt?: Date | null;
  bedId?: string;
  unitId?: string;
  patient?: PatientSearchResult;
  isEndEstimated?: boolean;
};

const useMovementPageParams = () => {
  const flowMatch = useMatch('/lits/unit/:ids/:flow/*');
  const globalMatch = useMatch('/lits/unit/:ids/*');
  const [ids, setIds] = useState<string[]>([]);
  const [flow, setFlow] = useState<FlowType | undefined>();
  const match = flowMatch || globalMatch;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (match) {
      const { ids, flow } = match.params as { ids: string; flow?: FlowType };

      setIds((oldIds) => {
        const newIds = ids.split('-');
        const hasChanged = JSON.stringify(oldIds) !== JSON.stringify(newIds);
        return hasChanged ? newIds : oldIds;
      });
      setFlow((oldFlow) => {
        const { view } = getFilterFromParams(searchParams, ids.length > 1);
        const newFlow = flow || (view !== 'sheets' ? 'admission' : undefined);
        const hasChanged = oldFlow !== newFlow;

        return hasChanged ? flow : oldFlow;
      });
    }
  }, [match, searchParams]);

  return { ids, flow, view: searchParams.get('view') as ViewType | undefined };
};

const MovementsPage = ({ units, flow }: MovementsPageProps) => {
  const { appBarHeight, isFullScreen, setFullScreen } = useAppBarContext();
  const { isReadOnly } = useReadOnly();

  const navigate = useRef(useNavigate());
  const unitsId = useMemo<string[]>(() => units.map(({ id }) => id), [units]);

  useMovementsPageAppBar(unitsId);

  const { filter, setFilter } = useMovementsPageSearchParams(unitsId.length > 1);
  const [filterCount, setFilterCount] = useState<number>();

  const [addAdmissionModalState, setAddAdmissionModalState] = useState<AddAccomodationModalState>();
  const handleCloseModal = () => setAddAdmissionModalState(undefined);

  const selectedUnits = useMemo<Unit[]>(
    () => unitsId.map((id) => units.find((unit) => unit.id === id)).filter(Boolean) as Unit[],
    [unitsId, units]
  );

  const practitionerQueryParams = useMemo(() => {
    const params: PractitionerQueryParams = {
      unitIds: unitsId,
    };

    return params;
  }, [unitsId]);

  const unit = selectedUnits.length > 1 ? undefined : selectedUnits[0];
  const { title, pageTitle, subtitle } = useMemo(() => {
    if (!flow) {
      return { title: '', pageTitle: '', subtitle: '' };
    }
    return getTitles({ unit, units: selectedUnits, allUnits: units, flow, view: filter.view, date: filter.date });
  }, [unit, selectedUnits, units, flow, filter.view, filter.date]);

  const handleFilterChange = (filter: MovementsFilter) => {
    const newSearchParams = setFilter(filter);

    let searchParamsString = newSearchParams.toString();
    if (filter.view === 'sheets') {
      searchParamsString = newSearchParams.get('date')
        ? `view=${filter.view}&date=${newSearchParams.get('date')}`
        : `view=${filter.view}`;
    }

    navigate.current({
      pathname: `../${unitsId.join('-')}${filter.view !== 'sheets' ? `/${flow}` : ''}`,
      search: searchParamsString,
    });
  };

  const handleFlowChange = (flow: FlowType) => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.delete('movementTypes');
    searchParams.delete('movementStatuses');
    setFilter({ ...filter, movementTypes: [], movementStatuses: [] });

    navigate.current({
      pathname: `../${unitsId.join('-')}/${flow}`,
      search: searchParams.toString(),
    });
  };

  return (
    <>
      <PageTitle title={pageTitle} />
      {flow && (
        <PageContent>
          {!isFullScreen && (
            <>
              <PageHeader title={title} subheader={subtitle}>
                {!isReadOnly && filter.view !== 'sheets' && (
                  <Button
                    startIcon={<Plus />}
                    size="large"
                    disabled={flow === 'discharge' && selectedUnits.length > 1}
                    onClick={() =>
                      setAddAdmissionModalState({
                        unitId: unitsId.length === 1 ? unitsId[0] : undefined,
                        startAt: new Date(),
                      })
                    }
                  >
                    {flow === 'admission' ? i18n.createAdmission.title : i18n.sendNewAccommodationRequest}
                  </Button>
                )}
              </PageHeader>

              <PageSection sticky={{ top: appBarHeight, withShadow: true }}>
                <MovementsFilters
                  filter={filter}
                  flow={flow}
                  onFilterChange={handleFilterChange}
                  isMulti={selectedUnits.length > 1}
                  onFlowChange={handleFlowChange}
                  accommodationCount={filterCount}
                  practitionerQueryParams={practitionerQueryParams}
                />
              </PageSection>
            </>
          )}

          {filter.view === 'kanban' && !!selectedUnits.length && (
            <MovementsKanban
              filter={filter}
              flow={flow}
              unitId={selectedUnits[0].id}
              onFilterCountChange={setFilterCount}
              isReadOnly={isReadOnly}
            />
          )}
          {filter.view === 'list' && (
            <PageSection
              withBackground
              noGutter
              noMargin={isFullScreen}
              paddingTop={!isFullScreen ? 8 : 10}
              fullHeight
              lastSection
            >
              <MovementsList
                unitIds={unitsId}
                date={filter.date}
                flow={flow}
                filter={filter}
                onFilterCountChange={setFilterCount}
                isReadOnly={isReadOnly}
              />
            </PageSection>
          )}
          {filter.view === 'sheets' && (
            <PageSection
              withBackground
              noGutter
              scrollable
              noPaddingX
              noPaddingY={!isFullScreen}
              paddingTop={2}
              noMargin={isFullScreen}
              fullHeight={isFullScreen}
              lastSection
            >
              <MovementSheets unitIds={unitsId} date={filter.date} isReadOnly={isReadOnly} />
            </PageSection>
          )}

          {isFullScreen && (
            <IconButton
              variant="outlined"
              size="large"
              shape="rounded"
              sx={{
                position: 'absolute',
                top: 24,
                right: 24,
                zIndex: 50,
              }}
              onClick={() => setFullScreen(false)}
            >
              <CollapseIcon />
            </IconButton>
          )}
        </PageContent>
      )}
      <Routes>
        <Route path="accommodation/:id" element={<DetailsDialog />} />
        <Route path="cleaning/:id" element={<DetailsDialog />} />
        <Route path="mutation/:id" element={<DetailsDialog />} />
        <Route path="available-bed/:id" element={<DetailsDialog />} />
      </Routes>

      {flow === 'admission' ? (
        <CreateAdmissionDialog
          open={!!addAdmissionModalState}
          unitId={unitsId.length === 1 ? unitsId[0] : undefined}
          onClose={handleCloseModal}
          onSuccess={handleCloseModal}
          availableUnits={unitsId}
        />
      ) : (
        !!unitsId[0] && (
          <SendAccommodationRequestDialog
            unitId={unitsId[0]}
            open={!!addAdmissionModalState}
            onClose={handleCloseModal}
          />
        )
      )}
    </>
  );
};

const MovementsPageContainer = () => {
  const { loading, units } = useNavigableUnits();
  const { ids, flow, view } = useMovementPageParams();
  const navigate = useRef(useNavigate());
  const { appBarSelect } = useAppBarContext();
  const selectedUnits = useMemo<Unit[]>(
    () => ids.map((id) => units.find((unit) => unit.id === id)).filter(Boolean) as Unit[],
    [ids, units]
  );

  useEffect(() => {
    if (!loading && appBarSelect?.value && Array.isArray(appBarSelect.value) && appBarSelect.value.length > 0) {
      // Don't navigate if it is an accommodation details
      if (
        window.location.pathname.includes('accommodation') ||
        window.location.pathname.includes('mutation') ||
        window.location.pathname.includes('cleaning') ||
        window.location.pathname.includes('fare')
      ) {
        return;
      }
      navigate.current({
        pathname:
          view !== 'sheets'
            ? `/lits/unit/${appBarSelect.value.join('-')}/${flow || 'admission'}`
            : `/lits/unit/${appBarSelect.value.join('-')}`,
        search: window.location.search,
      });
    }
  }, [loading, appBarSelect?.value, flow, view]);

  useEffect(() => {
    if (!loading && selectedUnits.length !== ids.length) {
      if (selectedUnits.length > 0) {
        navigate.current(`/lits/unit/${selectedUnits.map(({ id }) => id).join('-')}${flow ? `/${flow}` : ''}`, {
          replace: true,
        });
      } else if (units.length > 0) {
        navigate.current(`/lits/unit/${units[0].id}${flow ? `/${flow}` : ''}`, {
          replace: true,
        });
      }
    }
  }, [loading, selectedUnits, ids, units, flow]);

  if (loading || !units.length || selectedUnits.length !== ids.length) {
    return null;
  }
  return (
    <Routes>
      <Route path=":ids/:flow/*" element={<MovementsPage units={selectedUnits} flow={flow || 'admission'} />} />
      <Route path=":ids/*" element={<MovementsPage units={selectedUnits} flow={flow || 'admission'} />} />
      <Route path="*" element={<Navigate to={units[0].id + '/admission'} replace />} />
    </Routes>
  );
};

const getTitles = ({
  units,
  allUnits,
  unit,
  flow,
  view,
  date,
}: {
  units?: Unit[];
  allUnits: Unit[];
  unit?: Unit;
  flow: FlowType;
  view: MovementsFilter['view'];
  date: MovementsFilter['date'];
}) => {
  let title = view === 'sheets' ? i18n.myMovementsTitle.movements : i18n.myMovementsTitle[flow];
  let pageTitle = i18n.myMovements;
  let subtitle = view === 'sheets' ? i18n.pageSubtitles.sheets : i18n.pageSubtitles.movements[flow];
  if (unit) {
    pageTitle += ` - ${unit.name}`;
  } else if (units && units.length > 0) {
    if (units.length > 1) {
      if (units.length === allUnits.length) {
        pageTitle += ` - ${i18n.healthCenter}`;
      } else {
        pageTitle += ` - ${units.length} ${i18n.selectedUnits}`;
      }
    } else {
      pageTitle += ` - ${units[0].name}`;
    }
  }

  subtitle += isToday(date)
    ? ` ${i18n.today.toLowerCase()}`
    : ` ${i18n.the.toLowerCase()} ${format(date, 'eeee d MMMM', { locale: fr })}`;
  return { title, pageTitle, subtitle };
};

export default MovementsPageContainer;
