import { Accommodation, Area, Patient } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

type UseAccommodationsProps = {
  locationId?: string;
  visitId?: string;
};

const useAccommodations = ({ locationId, visitId }: UseAccommodationsProps) => {
  const enabled = !!locationId || !!visitId;
  const query = enabled
    ? new Parse.Query(Accommodation)
        .include('unit', 'responsibleUnit', 'bed', 'visit', 'thesaurusItem', 'practitioners')
        .containedIn('status', ['IN_PROGRESS', 'PLANNED', 'COMPLETED'])
    : null;

  if (locationId) {
    query?.equalTo('bed', Area.createWithoutData(locationId));
  }

  if (visitId) {
    query?.equalTo('visit', Patient.createWithoutData(visitId)).exists('visit');
  }

  const { results, isLoading, isEnabled } = useParseQuery(query, {
    enabled,
  });

  return {
    accommodations: results,
    isLoading,
    isEnabled,
  };
};

export default useAccommodations;
