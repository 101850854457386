import { Timeline as TimelineImage } from 'common/components/Images';
import Timeline, { Period, TimelineRow, TimelineRowItem } from 'common/components/Timeline';
import { i18n } from 'common/locale';
import { formatName, getPatientAge, getPatientEmojiIcon } from 'common/utils';
import { EmptyContent, PageSection, useAppBarContext } from 'core/layout';
import { AddAccommodationDialog } from 'kurt/components';
import AccommodationCardEvent from 'kurt/components/EventCard/Variants/AccommodationCardEvent';
import HealthcareEnvironmentalCleaningEvent from 'kurt/components/EventCard/Variants/HealthcareEnvironmentalCleaningEvent';
import { getCardColor } from 'kurt/components/EventCard/cardColors';
import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseBedCardEvent from '../../../../components/EventCard/Variants/CloseBedCardEvent';
import BedName from '../../BedName';
import { Resource } from '../../UnitManagement';
import getSkeletonEvents from './getSkeletonEvents';

const BedTimeline = ({
  resources,
  top = 0,
  period = 'week',
  from: start,
  to: end,
  container,
  loading,
  isReadOnly = false,
  isOver = false,
}: {
  date?: Date;
  top?: number;
  period?: Period;
  resources: Resource[];
  container?: RefObject<HTMLElement>;
  loading?: boolean;
  from?: Date;
  to?: Date;
  isReadOnly?: boolean;
  isOver?: boolean;
}) => {
  const { isFullScreen } = useAppBarContext();
  const navigation = useRef(useNavigate());

  const [newAccommodation, setNewAccommodation] = useState<
    { bedId: string; startAt: Date; unitId: string } | undefined
  >();

  const handleNavigateToAccommodation = useCallback((id?: string) => {
    if (id) {
      navigation.current(`accommodation/${id}`, {
        state: { from: window.location.pathname + window.location.search },
      });
    }
  }, []);

  const handleCreateAccommodation = (bedId: string, start: Date, unitId: string) => {
    setNewAccommodation({ bedId, startAt: start, unitId });
  };

  const handleCloseDialog = () => {
    setNewAccommodation(undefined);
  };

  const rows = useMemo(() => {
    const rows = [];

    for (const resource of resources) {
      rows.push({
        id: resource.bed.id,
        name: resource.bed.name,
        unitId: resource.bed.unitId,
        accommodations: resource.accommodations,
        anomalies: resource.anomalies,
        healthcareEnvironmentalCleanings: resource.healthcareEnvironmentalCleanings,
      });
    }

    return rows;
  }, [resources]);

  if (!start || !end) {
    return null;
  }

  return (
    <>
      <PageSection
        scrollable
        noGutter
        noMargin={isFullScreen}
        paddingTop={!isFullScreen ? 6 : 8}
        withBackground
        fullHeight
        lastSection
      >
        {!loading && rows.length === 0 ? (
          <EmptyContent Image={TimelineImage} {...i18n.accommodationEmptyContent} />
        ) : (
          <Timeline container={container} period={period} start={start} end={end} title={i18n.bed} top={top}>
            {rows.map(({ id, name, accommodations, anomalies, healthcareEnvironmentalCleanings, unitId }, index) => (
              <TimelineRow
                key={id}
                isOver={isOver}
                title={
                  <BedName
                    name={name}
                    anomalies={anomalies}
                    containerProps={{
                      paddingLeft: !anomalies?.length ? 8 : undefined,
                      marginRight: 2,
                      style: {
                        marginLeft: anomalies?.length ? 14 : undefined,
                      },
                    }}
                  />
                }
                onButtonClick={!isReadOnly ? (start) => handleCreateAccommodation(id, start, unitId) : undefined}
                isFirstRow={index === 0}
                isLastRow={index === rows.length - 1}
              >
                {!loading &&
                  healthcareEnvironmentalCleanings?.map((healthcareEnvironmentalCleaning) => (
                    <HealthcareEnvironmentalCleaningEvent
                      id={healthcareEnvironmentalCleaning.id}
                      key={healthcareEnvironmentalCleaning.id}
                      healthcareEnvironmentalCleaning={healthcareEnvironmentalCleaning}
                      start={healthcareEnvironmentalCleaning.startAt}
                      end={healthcareEnvironmentalCleaning.endAt}
                      color="primaryAlt"
                      emoji={{
                        name: 'bucket',
                        shape: 'square',
                      }}
                      onClick={() =>
                        navigation.current(`cleaning/${healthcareEnvironmentalCleaning.id}`, {
                          state: { from: window.location.pathname + window.location.search },
                        })
                      }
                      registerSticky
                    />
                  ))}
                {loading
                  ? getSkeletonEvents(period, index).map(({ start, end }, index) => (
                      <TimelineRowItem start={start} end={end} key={index} loading />
                    ))
                  : accommodations.map((accommodation) =>
                      accommodation.visit && accommodation.visit.id ? (
                        <AccommodationCardEvent
                          registerSticky
                          key={accommodation.id}
                          id={accommodation.id}
                          start={accommodation.startAt}
                          end={accommodation.endAt}
                          specificities={accommodation.specificities}
                          practitioners={accommodation.practitioners}
                          comment={accommodation.comment}
                          patient={{
                            name: formatName(
                              accommodation.visit.firstName,
                              accommodation.visit.lastName,
                              accommodation.visit.legalName,
                              accommodation.visit.legalFirstName
                            ),
                            gender: accommodation.visit.gender,
                            birthdate: accommodation.visit.birthdate,
                          }}
                          status={accommodation.status}
                          isEstimatedEnd={accommodation.isEstimatedEnd}
                          onClick={handleNavigateToAccommodation}
                          isEndOutdated={
                            anomalies &&
                            !!anomalies.find(
                              (anomaly) =>
                                anomaly.accommodation &&
                                anomaly.accommodation.id === accommodation.id &&
                                ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END'].includes(anomaly.type)
                            )
                          }
                          isStartOutdated={
                            anomalies &&
                            !!anomalies.find(
                              (anomaly) =>
                                anomaly.accommodation &&
                                anomaly.accommodation.id === accommodation.id &&
                                anomaly.type === 'OUTDATED_START'
                            )
                          }
                          emoji={{
                            name: getPatientEmojiIcon(
                              accommodation.visit.gender,
                              getPatientAge(accommodation.visit.birthdate)
                            ),
                            shape: 'circle',
                          }}
                          color={getCardColor(accommodation.specificities)}
                          striped={accommodation.status === 'COMPLETED'}
                        />
                      ) : (
                        <CloseBedCardEvent
                          id={accommodation.id}
                          key={accommodation.id}
                          start={accommodation.startAt}
                          end={accommodation.endAt}
                          onClick={handleNavigateToAccommodation}
                          specificities={accommodation.specificities}
                          comment={accommodation.comment}
                          emoji={{
                            name: 'construction',
                            shape: 'circle',
                          }}
                          color="secondary"
                          striped
                          registerSticky
                        />
                      )
                    )}
              </TimelineRow>
            ))}
          </Timeline>
        )}
      </PageSection>

      <AddAccommodationDialog
        open={!!newAccommodation}
        onClose={handleCloseDialog}
        onSuccess={handleCloseDialog}
        accommodation={{ ...newAccommodation, isEndEstimated: true }}
      />
    </>
  );
};

export default BedTimeline;
