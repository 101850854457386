import { Radio } from '@mui/material';
import { palette, color as themeColor, transition } from 'common/theme';

export const radioButtonColorNames = [
  'blue',
  'lightBlue',
  'green',
  'lightGreen',
  'brown',
  'orange',
  'lightBrown',
  'pink',
  'red',
  'default',
] as const;

export type RadioButtonColor = (typeof radioButtonColorNames)[number];

export const radioButtonColors: Record<RadioButtonColor, string> = {
  blue: '#78A5D0',
  lightBlue: '#8DCADF',
  green: '#8AC2B3',
  lightGreen: '#B5B98B',
  brown: '#827664',
  orange: '#DFBD7F',
  lightBrown: '#CEAA9E',
  pink: '#CBA5C9',
  red: '#F6A69B',
  default: themeColor.grey[30],
};

type ColorRadioButtonProps = {
  color: RadioButtonColor;
  isSelected: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ColorRadioButton = ({ color, isSelected, onChange }: ColorRadioButtonProps) => {
  const isDefault = color === 'default';
  return (
    <Radio
      checked={isSelected}
      onChange={onChange}
      value={radioButtonColors[color]}
      sx={{
        transition: `opacity ${transition}`,
        position: 'relative',
        padding: 0,
        color: radioButtonColors[color],
        opacity: isDefault && !isSelected ? 0.4 : 1,
        svg: {
          fontSize: 24,
          transform: 'scale(1)',
          '&:first-of-type': {
            color: themeColor.grey[30],
          },
        },
        '&.Mui-checked svg:first-of-type': {
          color: palette.primary.main,
        },
        '&.Mui-checked svg:last-of-type': {
          color: radioButtonColors[color],
        },
        '&:after': isDefault
          ? {
              content: '""',
              position: 'absolute',
              width: '100%',
              height: 2,
              right: 0,
              bottom: 'calc(50% - 1px)',
              backgroundColor: palette.secondary.main,
              transform: 'rotate(-45deg)',
            }
          : undefined,
      }}
    />
  );
};

export default ColorRadioButton;
