import { AccommodationSpecificity, DelayRule, isIsolatedSpecificity, isolatedSpecificities } from '@ambuliz/sabri-core';
import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Button, IconButton, Portal, Stack } from '@mui/material';
import CollapseIcon from 'common/components/Icons/Collapse';
import PlusIcon from 'common/components/Icons/Plus';
import SidebarIcon from 'common/components/Icons/Sidebar';
import { i18n } from 'common/locale';
import { theme, transition } from 'common/theme';
import shadows from 'common/theme/shadows';
import { formatName } from 'common/utils';
import { useAuthentication, useReadOnly } from 'core/authentication';
import { PageContent, PageHeader, PageSection, PageTitle, useAppBarContext } from 'core/layout';
import { Unit, useActiveBeds, useLocations } from 'core/locations';
import { useBedsFromUnit } from 'core/locations/useBedsFromUnit';
import useNavigableUnits from 'core/locations/useNavigableUnits';
import { AddAccommodationDialog, DetailsDialog } from 'kurt/components';
import PatientPlacementSidebar from 'kurt/components/PatientPlacement';
import PatientPlacementCard from 'kurt/components/PatientPlacement/PatientPlacementCard';
import { PATIENT_PLACEMENT_SIDEBAR_WIDTH } from 'kurt/components/PatientPlacement/PatientPlacementSidebar';
import { PatientToBePlaced } from 'kurt/components/PatientPlacement/usePatientPlacement';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SetBedDialog } from '../Dialogs';
import UnitStatistics from '../UnitStatistics';
import { UnitStatisticsType } from '../UnitStatistics/UnitStatistics';
import { Grid, List, Timeline } from '../views';
import AccommodatedResources from './AccommodatedResources';
import UnitManagementFilters, { BedStatus } from './UnitManagementFilters';
import useLiveDates from './useLiveDates';
import useResources, { Resource, filterResources } from './useResources';
import useResourcesByResponsibleUnits from './useResourcesByResponsibleUnits';
import useUnitManagementSearchParams from './useUnitManagementSearchParams';
import { PractitionerQueryParams } from 'common/components/Practitioners';

const UnitManagement = ({ units: unitIds = [] }: { units: string[] }) => {
  const { healthCenter } = useAuthentication();
  const { isFullScreen, setFullScreen } = useAppBarContext();
  const { isReadOnly } = useReadOnly();
  const navigate = useNavigate();
  const { loading: navigableUnitsLoading, units: allUnits } = useNavigableUnits();

  const { getUnit } = useLocations();
  const [isNewAccommodationModalOpened, setNewAccommodationModalOpened] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const unitContainer = useRef<HTMLDivElement>(null);
  const unitFiltersRef = useRef<HTMLDivElement>(null);

  const [activePatient, setActivePatient] = useState<PatientToBePlaced>();
  const [isDropValid, setDropValid] = useState(false);
  const [isOverTimeline, setOverTimeline] = useState(false);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 1,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const units = useMemo(() => unitIds.map((id) => getUnit(id)).filter(Boolean), [unitIds, getUnit]);
  const { bedsWithUnits } = useBedsFromUnit(unitIds, { filterInactive: false });
  const { beds, loading: activeBedsLoading } = useActiveBeds(bedsWithUnits);

  const unit = units.length > 1 ? undefined : units[0];
  const hasMultipleUnitsSelected = !unit && units.length > 1;

  const [filter, setFilter] = useUnitManagementSearchParams(hasMultipleUnitsSelected);
  const [filterCount, setFilterCount] = useState(0);

  const { from, to, refreshDates } = useLiveDates(filter.view, filter.period);

  const { resources, loading } = useResources(beds, from, to, filter.view, activeBedsLoading);
  const { resourcesByUnit, loading: resourcesByUnitLoading } = useResourcesByResponsibleUnits({
    unitIds: useMemo(() => units.map(({ id }) => id), [units]),
    start: from,
    end: to,
    view: filter.view,
  });

  const statistics = useMemo(
    () => getStatistics(resources, resourcesByUnit, healthCenter.bookedBedDelayRule),
    [resources, resourcesByUnit, healthCenter.bookedBedDelayRule]
  );

  const { filteredResources, filteredResourcesByUnit } = useMemo(() => {
    const filteredResources = filterResources(resources, filter, healthCenter.bookedBedDelayRule);
    const filteredResourcesByUnit = resourcesByUnit.map(({ resources, unit }) => {
      return { unit, resources: filterResources(resources, filter) };
    });

    let count = 0;
    let accommodatedCount = 0;

    if (filter.view !== 'list') {
      count = filteredResources.length;
      accommodatedCount = filteredResourcesByUnit.map(({ resources }) => resources).flat().length;
    }
    setFilterCount(count + accommodatedCount);

    return { filteredResources, filteredResourcesByUnit };
  }, [resources, resourcesByUnit, filter, healthCenter.bookedBedDelayRule]);

  const practitionerQueryParams = useMemo(() => {
    const params: PractitionerQueryParams = {
      unitIds: units.map(({ id }) => id),
    };

    return params;
  }, [units]);

  const toggleModal = useCallback(() => setNewAccommodationModalOpened((state) => !state), []);

  const { title, pageTitle } = useMemo(() => getTitles({ unit, units, allUnits }), [unit, units, allUnits]);

  const handleSpecificityClick = (specificity: AccommodationSpecificity) => {
    let specificities: AccommodationSpecificity[] = [];

    if (isIsolatedSpecificity(specificity)) {
      specificities = toggleIsolatedSpecificitiesFilter(filter.specificities);
    } else if (filter.specificities.includes(specificity)) {
      specificities = filter.specificities.filter((spec) => spec !== specificity);
    } else {
      specificities = [...filter.specificities, specificity];
    }
    setFilter({ ...filter, specificities });
  };

  const handleStatusClick = (status: BedStatus) => {
    if (!filter.statuses.includes(status)) {
      setFilter({ ...filter, statuses: [...filter.statuses, status] });
    } else {
      setFilter({ ...filter, statuses: filter.statuses.filter((spec) => spec !== status) });
    }
  };

  useEffect(() => {
    if (filter.view !== 'timeline' && sidebarOpen) {
      setSidebarOpen(false);
    }
  }, [filter.view, sidebarOpen]);

  if (!loading && units.length !== unitIds.length) {
    if (units.length > 0) {
      navigate(`/lits/management/unit/${units.map(({ id }) => id).join('-')}`);
    } else if (!navigableUnitsLoading && allUnits.length > 0) {
      navigate(`/lits/management/unit/${allUnits[0].id}`);
    }
    return null;
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event;

    if (over?.id !== 'patientPlacementComponent') {
      setDropValid(true);
      setOverTimeline(false);
    } else {
      handleDialogClose();
    }
  };

  const handleDragMove = (event: DragMoveEvent) => {
    const { over } = event;

    if (over?.id !== 'patientPlacementComponent') {
      setOverTimeline(true);
    } else {
      setOverTimeline(false);
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    if (active && active.data && active.data.current) {
      setActivePatient(active.data.current.data);
    }
  };

  const handleDialogClose = () => {
    setDropValid(false);
    setActivePatient(undefined);
    setOverTimeline(false);
  };

  return (
    <>
      <DndContext
        onDragCancel={handleDialogClose}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <PageContent>
          <UnitManagementContent ref={unitContainer} open={sidebarOpen}>
            {!isFullScreen && (
              <>
                <PageTitle title={pageTitle} />

                <PageHeader title={title} subheader={i18n.pageSubtitles.beds}>
                  {!isReadOnly && (
                    <Button startIcon={<PlusIcon />} size="large" onClick={toggleModal}>
                      {i18n.addNewAccommodation}
                    </Button>
                  )}
                </PageHeader>

                <PageSection>
                  <UnitStatistics
                    statistics={statistics}
                    filter={filter}
                    loading={loading}
                    onSpecificityClick={handleSpecificityClick}
                    onStatusClick={handleStatusClick}
                  />
                </PageSection>
                <PageSection sticky ref={unitFiltersRef}>
                  <UnitManagementFilters
                    filter={filter}
                    onChange={(newFilter) => {
                      setFilter(newFilter);
                      refreshDates(newFilter.view, newFilter.period); // Update dates at the same time to avoid an offset between the period and the dates for perfomances reasons (Ticket #5654)
                    }}
                    filterCount={filterCount}
                    disableSwitchView={hasMultipleUnitsSelected}
                    practitionerQueryParams={practitionerQueryParams}
                  />
                </PageSection>
              </>
            )}

            {filter.view === 'grid' && (
              <PageSection
                withBackground
                noGutter
                paddingTop={10}
                noMargin={isFullScreen}
                fullHeight={filteredResourcesByUnit.length === 0}
              >
                <Grid resources={filteredResources} loading={loading} isReadOnly={isReadOnly} />
              </PageSection>
            )}

            {filter.view === 'timeline' && (
              <Timeline
                top={!isFullScreen ? unitFiltersRef.current?.getBoundingClientRect().height : 0}
                resources={filteredResources}
                container={unitContainer}
                loading={loading}
                from={from}
                to={to}
                period={filter.period}
                isReadOnly={isReadOnly}
                isOver={isOverTimeline}
              />
            )}
            {filter.view === 'list' && (
              <List
                resources={filteredResources}
                loading={loading}
                displayUnitColumn={hasMultipleUnitsSelected}
                filter={filter}
                onFiltered={(filteredRows) => setFilterCount(filteredRows.length)}
                isReadOnly={isReadOnly}
              />
            )}

            <AccommodatedResources
              container={unitContainer}
              filter={filter}
              loading={resourcesByUnitLoading}
              resourcesByUnit={filteredResourcesByUnit}
              from={from}
              to={to}
              isReadOnly={isReadOnly}
            />
          </UnitManagementContent>
        </PageContent>

        <Routes>
          <Route path="accommodation/:id" element={<DetailsDialog />} />
          <Route path="mutation/:id" element={<DetailsDialog />} />
          <Route path="cleaning/:id" element={<DetailsDialog />} />
          <Route path="available-bed/:id" element={<DetailsDialog />} />
        </Routes>

        {filter.view === 'timeline' && !sidebarOpen && (
          <Portal container={document.body}>
            <IconButton
              onClick={() => setSidebarOpen(true)}
              size="extra-large"
              variant="outlined"
              sx={{
                position: 'absolute',
                bottom: 108,
                right: 24,
                zIndex: 100,
                boxShadow: shadows[3],
              }}
            >
              <SidebarIcon sx={{ fontSize: 28 }} />
            </IconButton>
          </Portal>
        )}

        <PatientPlacementSidebar
          unitIds={unitIds}
          open={sidebarOpen}
          isReadOnly={isReadOnly}
          onClose={() => setSidebarOpen(false)}
          isActionInProgress={!!activePatient}
        />

        <AddAccommodationDialog
          open={isNewAccommodationModalOpened}
          onClose={toggleModal}
          onSuccess={toggleModal}
          accommodation={{
            unitId: unit?.id,
          }}
        />

        {isFullScreen && (
          <IconButton
            variant="outlined"
            size="large"
            shape="rounded"
            sx={{
              position: 'absolute',
              top: 24,
              right: 24 + (sidebarOpen ? PATIENT_PLACEMENT_SIDEBAR_WIDTH : 0),
              zIndex: 50,
              transition,
            }}
            onClick={() => setFullScreen(false)}
          >
            <CollapseIcon />
          </IconButton>
        )}

        <DragOverlay zIndex={theme.zIndex.appBar + 1}>
          {activePatient && (
            <PatientPlacementCard
              key={`overlay-${activePatient.id}`}
              patientToBePlaced={activePatient}
              onClick={() => {}}
              isDragged
            />
          )}
        </DragOverlay>

        {activePatient && activePatient.visit && isDropValid && (
          <SetBedDialog
            open={isDropValid}
            id={activePatient.id}
            start={activePatient.startAt}
            end={activePatient.endAt}
            patient={{
              name: formatName(activePatient.visit.lastName, activePatient.visit.firstName),
              gender: activePatient.visit.gender,
              birthdate: activePatient.visit.birthdate,
            }}
            initialUnitId={activePatient.unit.id}
            specificities={activePatient.specificities}
            onClose={handleDialogClose}
            onSuccess={handleDialogClose}
          />
        )}
      </DndContext>
    </>
  );
};

const getStatistics = (
  beds: Resource[] = [],
  resourcesByUnit: { unit: Unit; resources: Resource[] }[] = [],
  delayRule?: DelayRule
) => {
  const statistics: UnitStatisticsType = {
    TOTAL: beds.filter((bed) => bed.bed.status !== 'CLOSED' && !bed.bed.excludedFromOccupancy).length,
    AVAILABLE: 0,
    BUSY: 0,
    ISOLATED: 0,
    ISOLATED_AIR: 0,
    ISOLATED_DROPLET: 0,
    ISOLATED_CONTACT: 0,
    ISOLATED_PROTECTOR: 0,
    ACCOMMODATED: 0,
    ACCOMMODATED_OTHER_UNIT: 0,
    CLOSED: 0,
    COVID: 0,
    WAITING_FOR_DOWNSTREAM_BED: 0,
    CLEANING: 0,
    BOOKED: delayRule ? 0 : undefined,
    PRIVATE_ROOM: 0,
  };
  const now = new Date();

  for (const bed of beds) {
    const isExcludedFromOccupancy = ['AVAILABLE', 'BOOKED'].includes(bed.bed.status) && bed.bed.excludedFromOccupancy;
    const isCleaning = bed.bed.status === 'CLEANING';
    bed.accommodations.forEach((accommodation) => {
      if (accommodation.startAt <= now && (!accommodation.endAt || accommodation.endAt >= now)) {
        accommodation.specificities?.forEach((specificity) => statistics[specificity]++);
      }
    });
    bed.healthcareEnvironmentalCleanings?.forEach((cleaning) => {
      if (cleaning.startAt <= now && (!cleaning.endAt || cleaning.endAt >= now)) {
        statistics.CLEANING++;
      }
    });
    if (isExcludedFromOccupancy || isCleaning) {
      continue;
    }

    statistics[bed.bed.status]++;
  }

  for (const { resources } of resourcesByUnit) {
    for (const { accommodation } of resources) {
      if (accommodation) {
        statistics.ACCOMMODATED_OTHER_UNIT++;
      }
    }
  }
  return statistics;
};

const getTitles = ({ units, allUnits, unit }: { units?: Unit[]; allUnits: Unit[]; unit?: Unit }) => {
  let title = i18n.myBeds;
  let pageTitle = title;
  if (unit) {
    title = unit.name;
    pageTitle += ` - ${unit.name}`;
  } else if (units && units.length > 0) {
    if (units.length > 1) {
      if (units.length === allUnits.length) {
        pageTitle += ` - ${i18n.healthCenter}`;
      } else {
        pageTitle += ` - ${units.length} ${i18n.selectedUnits}`;
      }
    } else {
      pageTitle += ` - ${units[0].name}`;
    }
  }
  return { title, pageTitle };
};

const toggleIsolatedSpecificitiesFilter = (specificities: AccommodationSpecificity[]) => {
  const hasIsolatedSpecificity = specificities.some((spec) => isIsolatedSpecificity(spec));

  if (hasIsolatedSpecificity) {
    return specificities.filter((spec) => !isIsolatedSpecificity(spec));
  }
  return Array.from(new Set([...specificities, ...isolatedSpecificities]));
};

const UnitManagementContent = styled(Stack)<{ open: boolean }>`
  overflow: auto;
  flex: 1;
  flex-grow: 1;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-right: ${({ open }) => (open ? PATIENT_PLACEMENT_SIDEBAR_WIDTH : 0)}px;
`;

export default UnitManagement;
