import { Cloud, Practitioner } from '@ambuliz/sabri-core';
import { PaletteOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DialogHeader } from 'common/components';
import UserIcon from 'common/components/Icons/UsersOutline';
import useParseResource from 'common/hooks/useParseResource';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ColorRadioButton, { radioButtonColorNames, radioButtonColors } from './ColorRadioButton';

const UpdatePractitionerDialog = () => {
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const { resource: practitioner, isLoading } = useParseResource(id, Practitioner);
  const [updateLoading, setUpdateLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = (refresh = false) => {
    setOpen(false);
    setShouldRefresh(refresh);
  };

  const handleRedirect = () =>
    navigate('..', {
      state: {
        refresh: shouldRefresh,
      },
    });

  const handleSubmit = async (practitioner: UpdatePractitionerFormState) => {
    if (!practitioner || !id) {
      return;
    }

    setUpdateLoading(true);
    try {
      await Cloud.updatePractitioner({ id, ...practitioner });
      toast.success(i18n.updatePractitionerDialog.successToast);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setUpdateLoading(false);
      handleClose(true);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth="md" onTransitionExited={handleRedirect}>
      {isLoading || !practitioner ? (
        <Stack m={4} alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <DialogHeader onClose={() => handleClose()} title={i18n.updatePractitionerDialog.title} />
          <UpdatePractitionerForm
            practitioner={practitioner}
            loading={updateLoading}
            onSubmit={handleSubmit}
            onClose={() => handleClose()}
          />
        </>
      )}
    </Dialog>
  );
};

type UpdatePractitionerFormState = {
  firstName: string;
  lastName: string;
  color: string;
};

type UpdatePractitionerFormProps = {
  practitioner: Practitioner;
  loading?: boolean;
  onSubmit: (practitioner: UpdatePractitionerFormState) => void;
  onClose: () => void;
};

const UpdatePractitionerForm = ({
  loading,
  practitioner: initialPractitioner,
  onSubmit,
  onClose,
}: UpdatePractitionerFormProps) => {
  const [practitioner, setPractitioner] = useState<UpdatePractitionerFormState>({
    firstName: initialPractitioner.firstName,
    lastName: initialPractitioner.lastName,
    color: initialPractitioner.color || radioButtonColors.default,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(practitioner);
  };

  const handleChange = (key: keyof UpdatePractitionerFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPractitioner((prev) => ({ ...prev, [key]: event.target.value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Stack divider={<Divider orientation="horizontal" style={{ marginLeft: 32 }} />}>
          <Stack direction="row" padding={3} gap={1} alignItems="center">
            <Stack direction="row" alignItems="center" gap={2} minWidth={190}>
              <UserIcon sx={{ fontSize: 12 }} color="secondary" />
              <Typography fontWeight={600}>{i18n.updatePractitionerDialog.nameInputLabel}</Typography>
            </Stack>
            <Stack direction="row" gap={2} flex={1}>
              <TextField
                variant="filled"
                size="small"
                placeholder={i18n.updatePractitionerDialog.lastName}
                value={practitioner.lastName}
                onChange={handleChange('lastName')}
                sx={{
                  flex: 1,
                }}
              />
              <TextField
                variant="filled"
                size="small"
                placeholder={i18n.updatePractitionerDialog.firstName}
                value={practitioner.firstName}
                onChange={handleChange('firstName')}
                sx={{
                  flex: 1,
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" padding={3} gap={1} alignItems="center">
            <Stack direction="row" alignItems="center" gap={2} minWidth={190}>
              <PaletteOutlined sx={{ fontSize: 12 }} color="secondary" />
              <Typography fontWeight={600}>{i18n.updatePractitionerDialog.colorInputLabel}</Typography>
            </Stack>
            <Stack direction="row" gap={3} alignItems="center">
              {radioButtonColorNames.map((color) => (
                <ColorRadioButton
                  key={color}
                  color={color}
                  isSelected={practitioner.color === radioButtonColors[color]}
                  onChange={(event) => {
                    setPractitioner((prev) => ({ ...prev, color: event.target.value }));
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {i18n.cancel}
        </Button>
        <LoadingButton type="submit" loading={loading}>
          {i18n.updatePractitionerDialog.submitButtonLabel}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default UpdatePractitionerDialog;
