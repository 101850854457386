import { useRefreshOnNavigate } from 'common/hooks/useRefreshOnNavigate';
import useNavigableUnits from 'core/locations/useNavigableUnits';
import { useMemo, useState } from 'react';
import PractitionersPage from './PractitionersPage';
import usePaginatedPractitioners from './usePaginatedPractitioners';

const PractitionersContainer = () => {
  const { units, loading: unitsLoading } = useNavigableUnits();
  const [unitId, setUnitId] = useState<string | undefined>(units[0]?.id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const {
    practitioners,
    loading: practitionersLoading,
    count,
    refetch,
  } = usePaginatedPractitioners({
    unitIds: [unitId] as string[],
    page,
    rowsPerPage,
    enabled: units.length > 0 && !unitsLoading,
  });

  useRefreshOnNavigate(refetch);

  const unitName = useMemo(() => units.find((unit) => unit.id === unitId)?.name, [unitId, units]);
  if (!unitId || !unitName) {
    return;
  }

  const handleUnitChange = (unitId: string) => {
    setUnitId(unitId);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
  };

  return (
    <PractitionersPage
      unitId={unitId}
      unitName={unitName}
      units={units}
      onUnitChange={handleUnitChange}
      practitioners={practitioners}
      loading={unitsLoading || practitionersLoading}
      displayUnitSelect={units.length > 1}
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};

export default PractitionersContainer;
