import Api, { Accommodation, AccommodationRequest, HealthCenter } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';
import { useLocations } from 'core/locations';
import { Department } from 'core/locations/LocationContext';
import { endOfDay } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { AccommodationSearchFilter } from '../Components/AccommodationSearchFilters';

export interface OverviewResource {
  accommodation: Accommodation;
  department?: string;
  accommodationRequest?: AccommodationRequest;
  destination?: Accommodation;
}

export const useOverviewResources = (
  { date, page, rowsPerPage }: AccommodationSearchFilter,
  healthCenterId: string
) => {
  const { departments } = useLocations();

  const filterDate = useMemo(() => date || new Date(), [date]);
  const endOfFilterDate = useMemo(() => endOfDay(filterDate), [filterDate]);

  const [resources, setResources] = useState<OverviewResource[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const query = new Api.Query(Accommodation)
    .lessThanOrEqualTo('startAt', endOfFilterDate)
    .greaterThanOrEqualTo('endAt', filterDate)
    .equalTo('healthCenter', HealthCenter.createWithoutData(healthCenterId))
    .exists('visit')
    .containedIn('status', ['PLANNED', 'IN_PROGRESS'])
    .include('practitioners');

  const { count } = useParseQueryCount(query);

  const { isLoading: isAccommodationsLoading, results: accommodations } = useParseQuery(
    query
      .limit(rowsPerPage)
      .skip(page * rowsPerPage)
      .include('visit', 'responsibleUnit', 'bed', 'unit')
  );

  const accommodationsWithDepartment = useMemo(() => {
    return mapDepartmentsToAccommodations(accommodations, departments);
  }, [accommodations, departments]);

  const visits = accommodations.map((accommodation) => accommodation.visit);

  const { results: accommodationRequests, isLoading: isAccommodationRequestsLoading } = useParseQuery(
    new Api.Query(AccommodationRequest)
      .containedIn('visit', visits)
      .notEqualTo('status', 'CANCELLED')
      .include('performerRequests', 'performerRequests.performer'),
    {
      enabled: !isAccommodationsLoading,
    }
  );

  const { results: nextAccommodations, isLoading: isNextAccommodationsLoading } = useParseQuery(
    new Api.Query(Accommodation).containedIn('basedOn', accommodationRequests).include('bed'),
    {
      enabled: !isAccommodationRequestsLoading,
    }
  );

  const isLoadingFinished = !isAccommodationsLoading && !isAccommodationRequestsLoading && !isNextAccommodationsLoading;

  useEffect(() => {
    setIsLoading(!isLoadingFinished);

    if (isLoadingFinished) {
      const resources = accommodationsWithDepartment.map(({ accommodation, department }) => {
        const accommodationRequest = accommodationRequests.find(
          (request) => request.visit?.id === accommodation.visit?.id && request.id !== accommodation.basedOn?.id
        );
        const nextAccommodation = nextAccommodations.find((a) => a.basedOn?.id === accommodationRequest?.id);
        return {
          accommodation,
          department,
          accommodationRequest,
          destination: nextAccommodation,
        } as OverviewResource;
      });
      setResources(resources);
      setIsLoading(false);
    }
  }, [accommodationsWithDepartment, accommodationRequests, nextAccommodations, isLoadingFinished]);

  return { resources, isLoading, count };
};

const mapDepartmentsToAccommodations = (accommodations: Accommodation[], departments: Department[]) => {
  return accommodations.map((accommodation) => {
    for (const department of departments) {
      if (department.units.some((unit) => unit.id === accommodation.unit.id)) {
        return {
          accommodation,
          department: department.name,
        } as OverviewResource;
      }
    }
    return { accommodation };
  });
};
