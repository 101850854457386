import {
  AccommodationSpecificity,
  AccommodationStatus,
  AnomalyType,
  PatientGender,
  Practitioner,
} from '@ambuliz/sabri-core';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Tooltip,
  Typography,
  cardHeaderClasses,
} from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Comment } from 'common/components/Icons';
import { PractitionersChip } from 'common/components/Practitioners';
import { i18n } from 'common/locale';
import { palette, transition } from 'common/theme';
import shadows from 'common/theme/shadows';
import { formatAge, getPatientAge } from 'common/utils';
import getShortDuration from 'common/utils/getShortDuration';
import { format } from 'date-fns';
import { AccommodationSpecificityBadges } from 'kurt/components';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import BedName from 'kurt/pages/Unit/BedName';
import { BusyBedActionsMenu } from 'kurt/pages/Unit/Menus';
import { memo, useState } from 'react';
import { CARD_CONTENT_PAPER_HEIGHT } from './cardContentPaperHeight';

type BusyBedCardProps = {
  bedId: string;
  bedName: string;
  bedGender: PatientGender;
  accommodationId: string;
  accommodationStatus: AccommodationStatus;
  accommodationSpecificities?: AccommodationSpecificity[];
  accommodationPractitioners?: Practitioner[];
  visitId: string;
  comment?: string;
  anomalies?: {
    accommodation?: {
      id: string;
    };
    type: AnomalyType;
  }[];
  start: Date;
  end?: Date;
  patientName: string;
  patientGender: PatientGender;
  patientBirthdate: Date;
  unitId: string;
  isEstimatedEnd: boolean;
  isReadOnly?: boolean;
  onClick: (id: string) => void;
};

const BusyBedCard = ({
  bedId,
  bedName,
  bedGender,
  accommodationId,
  accommodationStatus,
  accommodationSpecificities,
  accommodationPractitioners = [],
  visitId,
  comment,
  start,
  end,
  unitId,
  isEstimatedEnd,
  patientName,
  patientGender,
  patientBirthdate,
  anomalies,
  isReadOnly = false,
  onClick,
}: BusyBedCardProps) => {
  const [shouldHideOverlappingCard, setShouldHideOverlappingCard] = useState(false);

  const age = getPatientAge(patientBirthdate);

  return (
    <Box position="relative">
      {anomalies?.some(({ type }) => type === 'OVERLAPPING_STEPS') && (
        <Card
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            transform: shouldHideOverlappingCard ? 'scale(0.95) rotate(0deg)' : 'rotate(-3deg)',
            transition: `transform ${transition}`,
            left: -1,
            top: -1,
          }}
          elevation={1}
        />
      )}
      <Box position="relative">
        <Card
          onClick={() => onClick(accommodationId)}
          onMouseEnter={() => setShouldHideOverlappingCard(true)}
          onMouseLeave={() => setShouldHideOverlappingCard(false)}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              boxShadow: shadows[4],
            },
          }}
        >
          <CardHeader
            component="div"
            title={
              <Stack spacing={2} direction="row" alignItems="center">
                <BedName name={bedName} anomalies={anomalies} variant="h4" containerProps={{ width: undefined }} />
                <BedStatusChip status="BUSY" gender={bedGender} />
              </Stack>
            }
            action={
              <Stack direction="row" spacing={2} alignItems="center">
                {comment && (
                  <Tooltip title={comment}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Comment color="secondary" />
                    </Box>
                  </Tooltip>
                )}
                {!isReadOnly && (
                  <BusyBedActionsMenu
                    id={accommodationId}
                    status={accommodationStatus}
                    bedId={bedId}
                    unitId={unitId}
                    visitId={visitId}
                    comment={comment}
                    specificities={accommodationSpecificities}
                    onOpenChange={(isOpen) => setShouldHideOverlappingCard(isOpen)}
                  />
                )}
              </Stack>
            }
            sx={isReadOnly ? { [`& .${cardHeaderClasses.action}`]: { margin: '0 !important' } } : undefined}
          />
          <CardContent>
            <Stack spacing={3}>
              <Paper
                sx={{ height: CARD_CONTENT_PAPER_HEIGHT, backgroundColor: palette.background.default, padding: 2 }}
                variant="outlined"
              >
                <Stack justifyContent="space-between" height="100%">
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <PatientNameWithEmoji name={patientName} gender={patientGender} age={age} disableTooltip />
                    <Typography variant="body2" color="secondary" whiteSpace="nowrap">
                      {formatAge(patientBirthdate)}
                    </Typography>
                  </Stack>
                  <PractitionersChip practitioners={accommodationPractitioners} />
                </Stack>
              </Paper>
              <Stack spacing={4} direction="row" justifyContent="space-between" alignItems="center">
                <Dates
                  start={start}
                  end={end}
                  status={accommodationStatus}
                  isEstimatedEnd={isEstimatedEnd}
                  anomalies={anomalies?.filter((a) => a.accommodation?.id === accommodationId)}
                />
                {accommodationSpecificities && accommodationSpecificities?.length > 0 && (
                  <AccommodationSpecificityBadges specificities={accommodationSpecificities} />
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

const Dates = ({
  status,
  start,
  end,
  isEstimatedEnd,
  anomalies,
}: {
  status: string;
  start: Date;
  end?: Date;
  isEstimatedEnd: boolean;
  anomalies?: {
    type: AnomalyType;
  }[];
}) => {
  return (
    <Stack direction="row" width="100%" spacing={6}>
      <Stack width="100%" maxWidth={86}>
        <Typography variant="body2" color="secondary">
          {status === 'PLANNED' ? i18n.previsionalAdmissionShort : i18n.since}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          color={anomalies?.some((a) => a.type === 'OUTDATED_START') ? palette.warning.main : 'secondary'}
        >
          {status === 'IN_PROGRESS' ? getShortDuration(start) : format(start, 'dd/MM - HH:mm')}
        </Typography>
      </Stack>
      <Stack>
        <EllipsisTypography variant="body2" color="secondary">
          {status === 'COMPLETED'
            ? i18n.effectiveDischarge
            : isEstimatedEnd
              ? i18n.previsionalDischargeShort
              : i18n.validatedDischarge}
        </EllipsisTypography>
        <EllipsisTypography
          variant="body2"
          fontWeight="bold"
          color={
            anomalies?.some((a) => ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END'].includes(a.type))
              ? palette.warning.main
              : 'secondary'
          }
        >
          {end ? format(end, 'dd/MM - HH:mm') : '-'}
        </EllipsisTypography>
      </Stack>
    </Stack>
  );
};

export default memo(BusyBedCard);
