import Api, { Accommodation } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useMemo } from 'react';

const useDischargeAccommodation = (accommodationId: string) => {
  const { results: accommodations, isLoading: accommodationsLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .include('visit', 'bed', 'unit', 'basedOn', 'practitioners')
      .equalTo('objectId', accommodationId!)
      .limit(1)
  );
  const accommodation = useMemo(() => (!!accommodations?.length ? accommodations[0] : undefined), [accommodations]);

  const { results: nextAccommodations, isLoading: nextAccommodationsLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .notEqualTo('status', 'CANCELLED')
      .greaterThan('startAt', accommodation?.startAt)
      .equalTo('visit', accommodation?.visit)
      .ascending('startAt')
      .exists('basedOn')
      .include(
        'unit',
        'visit',
        'basedOn',
        'bed',
        'basedOn.performerRequests.performer',
        'basedOn.performerRequests.fulfilledBy',
        'basedOn.performerRequests.createdBy',
        'practitioners'
      )
      .limit(1),
    { enabled: !!accommodation }
  );
  const nextAccommodation = useMemo(
    () => (!!nextAccommodations?.length ? nextAccommodations[0] : undefined),
    [nextAccommodations]
  );

  return {
    accommodation,
    nextAccommodation,
    mutation: nextAccommodation?.basedOn,
    loading: accommodationsLoading || nextAccommodationsLoading,
    status: (accommodation?.status === 'COMPLETED'
      ? 'completed'
      : accommodation?.isEstimatedEnd
        ? 'planned'
        : 'validated') as 'completed' | 'planned' | 'validated' | 'requested',
  };
};

export default useDischargeAccommodation;
