import { Practitioner } from '@ambuliz/sabri-core';
import { Chip, Stack, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { color } from 'common/theme';
import { formatPractitionerName } from 'common/utils';

type PractitionersChipProps = {
  practitioners: Practitioner[];
};

const PractitionersChip = ({ practitioners }: PractitionersChipProps) => {
  const remainingPractitioners = practitioners.slice(1);
  return practitioners.length > 0 ? (
    <Stack direction="row" gap={1} alignItems="center">
      <Chip
        key={practitioners[0].id}
        label={
          <EllipsisTypography fontSize="inherit" fontWeight="inherit" lineHeight="inherit">
            {formatPractitionerName(practitioners[0].firstName, practitioners[0].lastName)}
          </EllipsisTypography>
        }
        sx={{ backgroundColor: practitioners[0].color }}
      />
      {remainingPractitioners.length > 0 && (
        <Typography variant="body2" color={color.grey[40]}>
          +{remainingPractitioners.length}
        </Typography>
      )}
    </Stack>
  ) : null;
};

export default PractitionersChip;
