import { Checkbox, MenuItem, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { FilterSelect } from 'common/components';
import { i18n } from 'common/locale';
import { useState } from 'react';
import PractitionersChip from './PractitionersChip';
import usePractitioners, { PractitionerQueryParams } from './usePractitioners';

export const PRACTITIONER_NOT_DEFINED_VALUE = 'practitioner-not-defined';

type PractitionersFilterProps = {
  practitionerQueryParams: PractitionerQueryParams;
  value: string[];
  onChange: (value: string[]) => void;
};

const PractitionersFilter = ({ practitionerQueryParams, value, onChange }: PractitionersFilterProps) => {
  const [enabled, setEnabled] = useState(false);
  const { practitioners, loading } = usePractitioners({ ...practitionerQueryParams, enabled });

  return (
    <FilterSelect
      multiple
      value={value}
      onChange={onChange}
      searchable
      label={i18n.practitioners}
      onOpen={() => setEnabled(true)}
      onClose={() => setEnabled(false)}
      noResultText={i18n.noPractitionersFound}
      notDefinedOption={{ value: PRACTITIONER_NOT_DEFINED_VALUE, label: i18n.practitionerNotDefined }}
    >
      {enabled && loading
        ? [1, 2, 3].map((i) => (
            <MenuItem
              key={i}
              className="loading-skeleton"
              disabled
              sx={{
                '&.Mui-disabled': {
                  opacity: 1,
                },
              }}
            >
              <Stack direction="row" gap={2} alignItems="center" padding="4px 4px 0">
                <Skeleton variant="rectangular" width={12} height={12} sx={{ borderRadius: 0.25 }} />
                <Skeleton variant="rectangular" width={200} height={16} sx={{ borderRadius: 0.5 }} />
              </Stack>
            </MenuItem>
          ))
        : practitioners.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={value.includes(option.id)} sx={{ marginRight: 1 }} />
              <PractitionersChip practitioners={[option]} />
            </MenuItem>
          ))}
    </FilterSelect>
  );
};

export default PractitionersFilter;
