import { AccommodationSpecificity, accommodationSpecificities } from '@ambuliz/sabri-core';
import { PRACTITIONER_NOT_DEFINED_VALUE } from 'common/components/Practitioners/PractitionersFilter';
import { SortOrder } from 'common/components/Table/SortableTableCell';
import { filterBySearch } from 'common/utils';
import { useEffect, useMemo, useState } from 'react';
import { MovementsFilter } from '../useMovementsPageSearchParams';
import { MovementRow, MovementStatus } from './MovementRow';

const useFilteredMovements = (
  movements: MovementRow[],
  filter: MovementsFilter,
  onFilterCountChange: (count: number) => void,
  flow: 'admission' | 'discharge'
) => {
  const [order, setOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof MovementRow>('date');

  const handleSort = (property: keyof MovementRow) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredMovements = useMemo(() => {
    const filteredMovements = filterMovements(movements, filter, flow);
    return filteredMovements.sort((a, b) => {
      const comparation = compareValues(a[orderBy], b[orderBy], getPropertyOrder(orderBy, order), orderBy);

      if (comparation === 0 && orderBy !== 'date') {
        return compareValues(a['date'], b['date'], getPropertyOrder('date', order), 'date');
      }
      return comparation;
    });
  }, [movements, filter, order, orderBy, flow]);

  useEffect(() => {
    onFilterCountChange(filteredMovements.length);
  }, [movements, filteredMovements.length, onFilterCountChange]);

  return {
    filteredMovements,
    order,
    orderBy,
    handleSort,
  };
};

const getPropertyOrder = (property: keyof MovementRow, order: SortOrder): SortOrder => {
  switch (property) {
    case 'patientBirthdate':
      return order === 'asc' ? 'desc' : 'asc';

    default:
      return order;
  }
};

const compareValues = (valueA: any, valueB: any, order: SortOrder, orderBy?: keyof MovementRow) => {
  if (valueA === undefined) {
    return order === 'asc' ? 1 : -1;
  }
  if (valueB === undefined) {
    return order === 'asc' ? -1 : 1;
  }

  if (orderBy === 'status') {
    const targetOrder: MovementStatus[] = [
      'incoming_mutation_requested',
      'outgoing_mutation_requested',
      'outgoing_mutation_blocked',
      'accommodation_to_assign',
      'accommodation_planned_discharge',
      'accommodation_assigned',
      'accommodation_confirmed_discharge',
      'accommodation_admitted',
      'accommodation_discharged',
    ];
    const indexA = targetOrder.indexOf(valueA);
    const indexB = targetOrder.indexOf(valueB);

    if (indexA !== -1 && indexB !== -1) {
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }

      return order === 'asc' ? indexA - indexB : indexB - indexA;
    }
  }
  if (orderBy === 'specificities') {
    return compareSpecificities(order, valueA, valueB);
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  }

  return order === 'asc' ? valueA - valueB : valueB - valueA;
};

const compareSpecificities = (
  order: SortOrder,
  valueA: AccommodationSpecificity[],
  valueB: AccommodationSpecificity[]
): number => {
  if (valueA.length <= 0 && valueB.length <= 0) {
    return 0;
  }
  const indexA = (valueA as AccommodationSpecificity[]).reduce(
    (acc, specificity) =>
      accommodationSpecificities.indexOf(specificity) < acc ? accommodationSpecificities.indexOf(specificity) : acc,
    accommodationSpecificities.length
  );
  const indexB = (valueB as AccommodationSpecificity[]).reduce(
    (acc, specificity) =>
      accommodationSpecificities.indexOf(specificity) < acc ? accommodationSpecificities.indexOf(specificity) : acc,
    accommodationSpecificities.length
  );

  if (indexA !== indexB) {
    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }

    return order === 'asc' ? indexA - indexB : indexB - indexA;
  }
  return compareSpecificities(order, valueA.slice(1), valueB.slice(1));
};

const filterMovements = (movements: MovementRow[], filter: MovementsFilter, flow: 'admission' | 'discharge') => {
  const filteredMovements: MovementRow[] = [];
  if (filter.search) {
    movements = filterBySearch({ search: filter.search, list: movements, keys: ['patientName'] });
  }

  for (const movement of movements) {
    if (filter.specificities.length > 0) {
      if (!filter.specificities.some((specificity) => movement.specificities?.includes(specificity))) {
        continue;
      }
    }
    if (filter.genders.length > 0) {
      if (filter.genders.indexOf(movement.patientGender) === -1) {
        continue;
      }
    }
    if (filter.movementTypes.length > 0) {
      if (movement.requestType) {
        if (movement.requestType === 'DIRECT_ADMISSION' && !filter.movementTypes.includes('DIRECT_ADMISSION')) {
          continue;
        } else if (
          ['INTERNAL_MUTATION', 'BED_MUTATION'].includes(movement.requestType) &&
          !filter.movementTypes.includes('INTERNAL_MUTATION')
        ) {
          continue;
        }
      } else {
        if (flow === 'admission' && !filter.movementTypes.includes('SCHEDULED_ADMISSION')) {
          continue;
        } else if (flow === 'discharge' && !filter.movementTypes.includes('SCHEDULED_DISCHARGE')) {
          continue;
        }
      }
    }
    if (filter.movementStatuses.length > 0) {
      if (!filter.movementStatuses.includes(movement.status)) {
        continue;
      }
    }
    if (filter.practitioners.length > 0) {
      if (
        filter.practitioners.includes(PRACTITIONER_NOT_DEFINED_VALUE) &&
        (!movement.practitioners || movement.practitioners.length === 0)
      ) {
        filteredMovements.push(movement);
        continue;
      }

      if (!movement.practitioners?.some((practitioner) => filter.practitioners.includes(practitioner.id))) {
        continue;
      }
    }
    filteredMovements.push(movement);
  }

  return filteredMovements;
};
export default useFilteredMovements;
