import { Button, IconButton } from '@mui/material';
import { Plus } from 'common/components/Icons';
import CollapseIcon from 'common/components/Icons/Collapse';
import { PatientSearchResult } from 'common/components/PatientAutocomplete';
import { PractitionerQueryParams } from 'common/components/Practitioners';
import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { AppBarSelect, PageHeader, useAppBarContext } from 'core/layout';
import { DetailsDialog } from 'kurt/components';
import CreateEmergencyAdmissionDialog from 'kurt/components/CreateEmergencyAdmissionDialog';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import EmergencyPageFilters from './EmergencyPageFilters';
import Hospitalizations from './Hospitalizations';
import PresentPatients from './PresentPatients';
import useEmergencyRouteParams from './useEmergencyRouteParams';
import useEmergencyUnits from './useEmergencyUnits';

const useUnitNavigation = () => {
  const { unitId, page } = useEmergencyRouteParams();

  const { units, loading, healthCenter } = useEmergencyUnits();

  const unit = useMemo(() => (unitId ? units.find(({ id }) => id === unitId) : units[0]), [unitId, units]);

  const navigate = useNavigate();

  const appBarSelect = useMemo(() => {
    if (units.length === 0 || loading || !unit) return undefined;

    const appBarSelect: AppBarSelect = {
      label: healthCenter?.name,
      value: unit.name,
    };

    if (units.length > 1) {
      appBarSelect.options = units.map(({ id, name }) => ({
        id,
        label: name,
        children: [],
      }));
      appBarSelect.value = unit.id;
    }

    return appBarSelect;
  }, [units, loading, unit, healthCenter?.name]);

  const { appBarSelect: select } = useAppBarContext({ appBarSelect });

  useEffect(() => {
    if (typeof select?.value === 'string' && units.length > 1 && select?.value !== unit?.id) {
      navigate(`../${select.value}/${page}`);
    }
  }, [navigate, select, units, unit?.id, page]);

  return {
    unit,
    loading,
  };
};

type AddAccomodationModalState = {
  id?: string;
  startAt?: Date;
  endAt?: Date | null;
  bedId?: string;
  unitId?: string;
  patient?: PatientSearchResult;
  isEndEstimated?: boolean;
};

const Emergency = () => {
  const { user } = useAuthentication();
  const { unit, loading } = useUnitNavigation();
  const { isFullScreen, setFullScreen } = useAppBarContext();
  const { page } = useEmergencyRouteParams();
  const [addAdmissionModalState, setAddAdmissionModalState] = useState<AddAccomodationModalState>();
  const handleCloseModal = () => setAddAdmissionModalState(undefined);

  const practitionerQueryParams = useMemo(() => {
    if (!unit) {
      return undefined;
    }

    const params: PractitionerQueryParams = {
      unitIds: [unit.id],
    };
    return params;
  }, [unit]);

  if (loading || !unit) {
    return <div>loading...</div>;
  }

  return (
    <>
      {!isFullScreen && (
        <>
          <PageHeader title={unit?.name || ''} subheader="État du service en temps réel">
            {['ADMIN', 'ADMIN_REGULATOR'].includes(user.role) && page === 'present-patients' && (
              <Button
                startIcon={<Plus />}
                size="large"
                onClick={() =>
                  setAddAdmissionModalState({
                    unitId: unit.id,
                    startAt: new Date(),
                  })
                }
              >
                {i18n.createAdmission.title}
              </Button>
            )}
          </PageHeader>
          <EmergencyPageFilters practitionerQueryParams={practitionerQueryParams} />
        </>
      )}

      <Outlet />

      {isFullScreen && (
        <IconButton
          variant="outlined"
          size="large"
          shape="rounded"
          sx={{
            position: 'absolute',
            top: 24,
            right: 24,
            zIndex: 50,
          }}
          onClick={() => setFullScreen(false)}
        >
          <CollapseIcon />
        </IconButton>
      )}

      <CreateEmergencyAdmissionDialog
        open={!!addAdmissionModalState}
        onClose={handleCloseModal}
        onSuccess={handleCloseModal}
        accommodation={{
          unitId: unit?.id,
        }}
      />
    </>
  );
};

const EmergencyPage = () => {
  const { units, loading } = useEmergencyUnits();

  if (loading || units.length === 0) {
    return <div>loading...</div>;
  }

  return (
    <>
      <Helmet title={i18n.emergencies} />
      <Routes>
        <Route path=":unitId/*" element={<Emergency />}>
          <Route path="present-patients" element={<PresentPatients />}>
            <Route path=":id" element={<DetailsDialog />} />
          </Route>
          <Route path="hospitalizations" element={<Hospitalizations />}>
            <Route path=":id" element={<DetailsDialog />} />
          </Route>
          <Route path="*" element={<Navigate to="present-patients" />} />
        </Route>
        <Route path="*" element={<Navigate to={units[0].id + '/present-patients'} replace />} />
      </Routes>
    </>
  );
};

export default EmergencyPage;
