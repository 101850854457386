import { ADMIN_ROUTES, KURT_ROUTES, NavigationRoute, SABRI_ROUTES } from '@ambuliz/sabri-core';
import { i18n } from 'common/locale';
import { i18n as sabriI18n } from 'sabri/locales';

export const ROUTE_LABELS: { [key in NavigationRoute]: string } = {
  kurt: i18n.kurt,
  'kurt.territory': i18n.territory,
  'kurt.healthCenter': i18n.healthCenter,
  'kurt.unit': i18n.myBeds,
  'kurt.movements': i18n.movements,
  'kurt.emergency': i18n.emergencies,
  sabri: i18n.sabri,
  'sabri.settings': i18n.settings,
  'sabri.fares': sabriI18n.fares,
  'sabri.team': sabriI18n.team,
  admin: i18n.admin,
  'admin.healthCenter': i18n.healthCenter,
  'admin.units': i18n.units,
  'admin.areas': i18n.areas,
  'admin.users': i18n.users,
  'admin.patients': i18n.patients,
  'admin.appointments': i18n.appointments,
  'admin.externalTransports': i18n.externalTransports,
  'admin.healthcareEnvironmentalCleaning': i18n.healthcareEnvironmentalCleaning,
  'admin.anomalies': i18n.anomalies,
  'admin.emergencies': i18n.emergencies,
  'admin.thesaurus': i18n.thesaurus,
  'admin.overview': i18n.overview,
  'admin.practitioners': i18n.practitioners,
};

export type ExtractChildRoutes<T extends readonly string[]> = Exclude<T[number], T[0]>[];

export type RouteWithChildren<T extends readonly string[]> = {
  parentRoute: T[0];
  label: string;
  children: { route: Exclude<T[number], T[0]>; label: string }[];
};

const createRouteTree = <T extends readonly string[]>(routes: T, label: string): RouteWithChildren<T> => {
  const parentRoute = routes[0];
  const childrenRoutes = routes.slice(1);

  return {
    parentRoute: parentRoute as T[0],
    label,
    children: childrenRoutes.map((route) => ({
      route: route as Exclude<T[number], T[0]>,
      label: ROUTE_LABELS[route as NavigationRoute],
    })),
  };
};

export const APP_ROUTES_TREE = [
  createRouteTree(KURT_ROUTES, ROUTE_LABELS['kurt']),
  createRouteTree(SABRI_ROUTES, ROUTE_LABELS['sabri']),
  createRouteTree(ADMIN_ROUTES, ROUTE_LABELS['admin']),
] as const;
